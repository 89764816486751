import { createTimestamp } from 'utils/dates';
import { Subscription, UserLocation } from 'interfaces/common';

export const getDefaultSubscription = (): Subscription => {
  return {
    membership: 'basic',
    startedAt: createTimestamp(),
    expiredAt: 0,
    isForever: true,
  };
};
export const identifiers = [];
export const UserLocationDefaultInformation: UserLocation = {
  city: 'Riyadh',
  country: {
    code: 'SA',
    name: 'Saudi Arabia',
  },
  location: {
    type: 'Point',
    coordinates: [46.7152, 24.6537],
  },
  geoHash: '',
  currency: 'SAR',
};
