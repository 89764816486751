import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    // marginTop: theme.spacing(5),
    // marginBottom: theme.spacing(5),
    // marginRight: theme.spacing(-5),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    // marginRight: theme.spacing(1),
  },
  exportButton: {
    // marginRight: theme.spacing(1),
  },
  searchInput: {
    // marginRight: theme.spacing(1),
  },
}));

interface Props {
  className?: any;
  children?: any;
}

const Toolbar = (props: Props) => {
  const { className, children, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {children}
      </div>
    </div>
  );
};
export default Toolbar;
