import { Res } from 'interfaces';
import { ImageFolder as Folder } from 'interfaces/common';
import { functions } from 'services/common';
import { create64BaseImageUrl } from 'utils/image';

export const uploadStorage = async (
  file: any,
  targetFolder: Folder,
  success: (data: { url: string; public_id: string }) => void,
  error: (msg: string) => void
) => {
  type UploadRes = {
    url: string;
    public_id: string;
    success: boolean;
  };

  const onLoaded = async (base64: string) => {
    try {
      const upload = await functions<UploadRes>({
        function: 'uploadImage',
        data: {
          file: base64,
          folder: targetFolder,
        },
      });
      console.log('Image upload respose', upload);
      if (upload.success) {
        success({
          url: upload.url,
          public_id: upload.public_id,
        });
      } else {
        error('something is wrong');
      }
    } catch (err) {
      console.log('ERR', err);
      error('something is wrong');
    }
  };
  create64BaseImageUrl(file, onLoaded);
};
export const addFileCustomData = async (
  url: string,
  data: { [key: string]: any } = {}
) => {
  const response: Res<undefined, 'error' | 'ok'> = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    await functions({
      function: 'metadataImage',
      data: {
        metadata: data,
        public_id: url,
      },
    });
    response.success = true;
    response.message = 'ok';
  } catch (error) {
    console.error('err while updating meta data', error);
  }
  return response;
};
export const deleteStorage = async (public_id: string) => {
  const response: Res = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    await functions({
      function: 'deleteImage',
      data: {
        public_id,
      },
    });
  } catch (err) {
    console.log('err while delete', err);
  }
  return response;
};
