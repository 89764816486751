import { db, generateDocumentId } from 'services/common';
import { categoryDB, menuDB } from 'config/db';
import { Name } from 'interfaces/common';
import { Category, Res } from 'interfaces';
import { categorySchemaValidation } from 'validations';
import { trackEvent } from 'services/analytics';
import _ from 'lodash';
export const fetchCategories = async (storeId: string) => {
  const categories: Category[] = [];
  try {
    const ref = db(categoryDB).where('storeId', '==', storeId);
    const data = await ref.get();

    data.docs.forEach((category: any) => {
      const categoryToPush = category.data();
      categories.push(categoryToPush);
    });
  } catch (error) {
    console.error('err while getting categories', error);
  }
  const sorted = categories.sort((a, b) => {
    return Number(a.path) - Number(b.path);
  });
  return sorted;
};
type msg = 'success' | 'validation error' | 'error';

export const addCategory = async (category: Category) => {
  const response: Res<undefined, msg> = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    const id = category.id;
    const validation = categorySchemaValidation(category);
    if (validation.isValid) {
      const validatedCateogry = validation.data;
      await db(categoryDB).doc(id).set(validatedCateogry);
      response.success = true;
      response.message = 'success';
      trackEvent('category_add');
    } else {
      response.message = 'validation error';
    }
  } catch (error) {
    console.log('err while adding category');
  }
  return response;
};
export const editCategory = async (category: Category) => {
  const response: Res<undefined, msg> = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    const validation = categorySchemaValidation(category);
    if (validation.isValid) {
      const validatedCateogry = validation.data;
      const result = await updateCategoriesItems(
        category.id,
        validatedCateogry.name,
        'update'
      );
      console.log('resultttt', result);
      await db(categoryDB).doc(category.id).update(validatedCateogry);
      trackEvent('category_update');
      response.success = true;
      response.message = 'success';
    } else {
      response.message = 'validation error';
    }
  } catch (error) {
    console.log('err', error);
  }
  return response;
};
export const getCategoryById = async (categoryId: string) => {
  type msg = 'error' | 'not found' | 'success';
  const response: Res<Category | undefined, msg> = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    const categoryRef = await db(categoryDB).doc(categoryId).get();
    //@ts-ignore
    const data: Category | undefined = categoryRef.data();
    if (data) {
      response.message = 'success';
      response.success = true;
      response.data = data;
    } else {
      response.message = 'not found';
    }
  } catch (error) {
    console.log('error while getting the cateogry', error);
  }
  return response;
};

const updateCategoriesItems = async (
  id: string,
  name: Name,
  operation: 'update' | 'delete'
) => {
  const response: Res = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    let query = { name: name, id: id };
    if (operation === 'update') {
      const oldCategoryInfo = (await getCategoryById(id)).data;
      if (oldCategoryInfo) {
        query.name = oldCategoryInfo.name;
      }
    }
    const menuItems = await db(menuDB)
      .where('categories', 'array-contains', query)
      .get();

    const updatedCategory = operation === 'update' ? [{ name, id }] : [];
    const indexes =
      operation === 'update'
        ? {}
        : {
            itemIndex: 9999,
            categoryIndex: 9999,
          };
    const result = await menuItems.docs.map(
      async (d: any) =>
        await db(menuDB)
          .doc(d.id)
          .update({
            categories: updatedCategory,
            ...indexes,
          })
    );
    const hello = await Promise.all(result);
    console.log('indexes', hello);
    response.success = true;
    response.message = 'ok';
  } catch (error) {
    console.log('error while updating items categories');
  }
  return response;
};
export const deleteCategory = async (id: string) => {
  try {
    const catetogryRes = await getCategoryById(id);

    const category = catetogryRes.data;
    if (category) {
      await updateCategoriesItems(id, category.name, 'delete');
      await db(categoryDB).doc(id).delete();
      trackEvent('category_delete');
    }
    return { success: catetogryRes.success, message: 'Category Deleted' };
  } catch (error) {
    return { success: false, message: 'Category deletion Failed' };
  }
};
