import { imageAllowedSizeInMB } from 'constant/values/common';
import imageCompression from 'browser-image-compression';
import Resizer from 'react-image-file-resizer';
import { Cloudinary } from '@cloudinary/url-gen';
import { limitFit } from '@cloudinary/url-gen/actions/resize';

const resizeFile = (file: File): Promise<File> => {
  //file, // Is the file of the image which will resized.
  const maxWidth = 2000; // Is the maxWidth of the resized new image.
  const maxHeight = 2000; // Is the maxHeight of the resized new image.
  const compressFormat = 'JPEG'; // Is the compressFormat of the resized new image.
  const quality = 100; // Is the quality of the resized new image.
  const rotation = 0; // Is the degree of clockwise rotation to apply to uploaded image.
  const outputType = 'file'; // Is the output type of the resized new image.
  const minWidth = undefined; // Is the minWidth of the resized new image.
  const minHeight = undefined; // Is the minHeight of the resized new image.

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      (uri) => {
        // Is the callBack function of the resized new image URI.
        //@ts-ignore
        resolve(uri);
      },
      outputType,
      minWidth,
      minHeight
    );
  });
};
export const compressFile = async (file: File, maxSizeMB = 8) => {
  const compressed = await imageCompression(file, {
    maxSizeMB,
  });
  return compressed;
};
export const createImageObjectURL = async (
  image: any,
  compressImage = true
) => {
  let success = false;
  let massage = 'image size is large. The image should less than 1 MB';
  let url = '';
  let file: File = image;
  const imageSize = image.size;
  const currentImageSizeInMB = imageSize / 1000 / 1000;

  console.log('before compressed', file);
  try {
    if (compressImage) {
      if (currentImageSizeInMB >= 8) {
        file = await compressFile(file);
        console.log('compressed', file);
      }
    }
    url = URL.createObjectURL(image);
    success = true;
    massage = 'ok';
  } catch (err) {
    console.log('ERROR', err);
    url = '';
    success = false;
  }
  return { url, massage, success, file };
};

export const create64BaseImageUrl = (
  file: File,
  onLoaded: (base64: string) => any
) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    if (typeof reader.result === 'string') {
      onLoaded(reader.result);
    }
  };
};
export const getCloudinaryImageURL = (public_id: string) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_cloud_name,
    },
  });
  const myImage = cld.image(public_id);
  // Resize to 250 x 250 pixels using the 'fill' crop mode.
  //myImage.resize(fit().width(1000).height(1000)).format('auto');
  //myImage.resize(fit().width(1000).height(1000));

  myImage.resize(limitFit().width(1000).height(1000)).format('auto');
  return myImage.toURL();
};
