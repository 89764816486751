import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';
import LoadingScreen from 'components/loading-screen';
import { useNotification } from 'hooks';
const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
  btn: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));
type DataType = { [key: string]: string | number };
interface Props {
  children?: any;
  text: string;
  getData: () => Promise<DataType[]>;
}

const CSVDownloadButton = (props: Props): ReactElement => {
  const classes = useStyles();
  const { getData, text } = props;
  const [data, setData] = useState<DataType[]>([]);
  const [fetching, setFetching] = useState(false);
  const fetchData = async (done: (proceed?: boolean) => any) => {
    setFetching(true);
    try {
      const newData = await getData();
      setData(newData);
      done(true);
    } catch (error) {
      console.error('error while fetchingData for CSV', error);
      done(false);
    }
    setFetching(false);
  };
  return (
    <Button variant="outlined" color="primary">
      <LoadingScreen loadingType="fullscreen" active={fetching} />
      <CSVLink
        className={classes.btn}
        data={data}
        asyncOnClick={true}
        onClick={(e, done) => fetchData(done)}
      >
        {text}
      </CSVLink>
    </Button>
  );
};

export default CSVDownloadButton;
