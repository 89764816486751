import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Copyright } from '../components';
import { NavBar, Language } from 'components';
import { UserContext } from 'context';
import paths from 'constant/paths';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

interface Props {
  children?: any;
  location?: any;
}

const Minmial = (props: Props): ReactElement => {
  const classes = useStyles();
  const { children, location } = props;
  let isLoggedIn = React.useContext(UserContext).isLoggedIn;
  if (location.pathname === paths.home) isLoggedIn = true;
  return (
    <>
      <div className={classes.root}>
        {isLoggedIn ? <NavBar /> : <Language topRight={true} />}
        {children}
      </div>
    </>
  );
};

const defaultProps: Props = {};

Minmial.defaultProps = defaultProps;

export default Minmial;
