import joi from 'joi';

const identifiersSchema = joi
  .array()
  .items(
    joi
      .object({
        value: joi.string().default(''),
        type: joi.string().default(''),
      })
      .options({ stripUnknown: true })
  )
  .default([]);

export default identifiersSchema;
