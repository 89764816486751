import { WebsiteMenu } from 'interfaces';
import { identifiers, selctedStore } from 'validations/default-values';

const menuWebsite: WebsiteMenu = {
  items: [],
  store: selctedStore,
  identifiers: identifiers,
  queries: [],
};
export { menuWebsite } from 'validations/default-values';
export default menuWebsite;
