import { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { itemImageHeight, itemImgaeWidth } from 'constant/values/common';
import { Card, CardContent, CardActionArea } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
  content: {
    backgroundColor: '#E8E8E8',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    height: '100%',
    fontSize: 'x-large',
  },
  addIcon: {
    height: '3rem',
    width: '3rem',
  },
  text: {
    marginTop: '1rem',
  },
}));

interface Props {
  children?: any;
  width?: number;
  height?: number;
  borderRadius?: number;
  boxShadow?: string;
  addText?: boolean;
  onClick: (e: any) => void;
}

const AddImage = (props: Props): ReactElement => {
  const { width, height, borderRadius, boxShadow, addText, onClick } = props;
  const classes = useStyles(props);

  return (
    <Card
      onClick={onClick}
      className={classes.root}
      style={{ width, height, borderRadius, boxShadow }}
    >
      <CardActionArea className={classes.content}>
        <CardContent className={classes.content}>
          <PhotoCameraIcon className={classes.addIcon} color="primary" />
          {!addText && (
            <Typography className={classes.text} variant="h6">
              Add Photo
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const defaultProps: Props = {
  width: itemImgaeWidth,
  height: itemImageHeight,
  onClick: () => undefined,
};

AddImage.defaultProps = defaultProps;

export default AddImage;
