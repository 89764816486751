import joi from 'joi';
import {
  nameSchema,
  timestampSchema,
  emailSchema,
  phoneSchema,
  uniqueIdSchema,
  specificStringSchema,
} from '../../common';
import { UserAccountType } from 'interfaces';
const storeAcessScheam = joi
  .array()
  .items(
    joi
      .object({
        storeId: uniqueIdSchema,
        name: nameSchema,
      })
      .options({ stripUnknown: true })
  )
  .default([]);
// User - interface
const accountType = specificStringSchema<UserAccountType>(
  'business',
  'consumer'
).default('business');
const userSchema = joi
  .object({
    name: nameSchema,
    userObjectId: uniqueIdSchema,
    docId: uniqueIdSchema,
    phone: phoneSchema,
    createdAt: timestampSchema,
    isVerified: joi.boolean().default(false),
    email: emailSchema,
    lastSignedIn: timestampSchema,
    storesAccess: storeAcessScheam,
    accountType: joi.array().items(accountType).default(['business']),
  })

  .options({ stripUnknown: true, abortEarly: false });

export default userSchema;
