import axios from 'axios';
import { Res } from 'interfaces';
import { UserLocation } from 'interfaces/common';
import currencyCode from 'currency-codes';

export const detectCountryAndCityFromIpAddress = async () => {
  type Data = {
    country_code: string;
    country_name: string;
    city: string;
    postal: null | string;
    latitude: number;
    longitude: number;
  };
  const response: Res<Data | undefined> = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    const fetch = await axios.get('https://geolocation-db.com/json/');
    const result: Data = fetch.data;
    response.data = result;
  } catch (error) {
    console.error('could not get the ip', error);
  }
  return response;
};
export const getUserLocationInformation = async () => {
  const response: Res<UserLocation | undefined> = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    const locationResult = await detectCountryAndCityFromIpAddress();
    const locationInfo = locationResult.data;
    if (locationInfo) {
      const currencyInfo = currencyCode.country(locationInfo.country_name)[0];
      if (currencyInfo) {
        const currency = currencyInfo.code;
        response.success = true;
        response.message = 'ok';
        response.data = {
          country: {
            name: locationInfo.country_name,
            code: locationInfo.country_code,
          },
          city: locationInfo.city,
          currency: currency,
          geoHash: '',
          location: {
            type: 'Point',
            coordinates: [locationInfo.longitude, locationInfo.latitude],
          },
        };
      }
    }
  } catch (error) {
    console.error('could not get user location info', error);
  }
  return response;
};
