import { Draggable } from 'react-beautiful-dnd';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)',
  },
});

export type Props = {
  children: any;
  index: number;
  draggableId: string;
};

const DraggableListItem = ({ draggableId, index, children }: Props) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ''}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
