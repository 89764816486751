import { useState } from 'react';
import _ from 'lodash';

const useError = () => {
  const [errors, setErrors] = useState({});

  const getErrorInfo = (field) => {
    let noFiled = {
      isError: false,
      message: '',
    };
    return errors[field] || noFiled;
  };

  const hasError = (field) => getErrorInfo(field).isError;

  const getErrorMessage = (field) => getErrorInfo(field).message;

  const updateError = (field, isError, message = '') => {
    const oldError = getErrorInfo(field);
    const newError = {
      isError,
      message,
    };
    const isEqual = _.isEqual(oldError, newError);
    if (isEqual === false) {
      setErrors((prev) => ({
        ...prev,
        [field]: newError,
      }));
    }
  };

  const bulkErrorUpdate = (joiError, includeMessage = true) => {
    let bulkErrors = {};
    joiError.map((err) => {
      const error = {
        isError: true,
        message: includeMessage ? err.message : '',
      };
      const key = err.key;
      bulkErrors[err.key] = error;
    });
    setErrors(bulkErrors);
  };

  const checkAllErrors = () => {
    let allErrors = {};
    let hasAnyError = false;
    for (const field in errors) {
      console.log(`${field}: ${errors[field]}`);
      const isError = errors[field].isError;
      if (isError) {
        hasAnyError = true;
        allErrors[field] = errors[field];
      }
    }
    return { allErrors, hasAnyError };
  };

  return {
    getErrorInfo,
    updateError,
    hasError,
    bulkErrorUpdate,
    getErrorMessage,
    checkAllErrors,
    errors,
  };
};

export default useError;
