import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import theme from 'theme';
import {
  ListItem,
  List,
  ListItemText,
  makeStyles,
  ListItemAvatar,
  styled,
  Avatar,
  Grid,
} from '@material-ui/core';
import { CategoryContext, ItemsContext } from 'context';
import { useTranslation } from 'react-i18next';
import { useName as translation } from 'hooks';
import _ from 'lodash';
import { DraggableList, DraggableItem, Icon } from 'components';
import { DropResult } from 'react-beautiful-dnd';
import { reorderBasedOnDraggableResult } from 'utils/array';
import { getCloudinaryImageURL } from 'utils';

const ContentWrapper = styled(DialogContent)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 24px 40px',
});

const useStyles = makeStyles({
  root: {},
  button: {
    textTransform: 'initial',
  },
  divider: {
    margin: '0 1rem',
    backgroundColor: `${theme.palette.primary.main}`,
    height: '110%',
    visibility: 'hidden',
  },
  description: {
    width: '100%',
    marginTop: '2rem',
  },
  languageSwitch: {
    display: 'flex',
  },
  arabicText: {
    '& label': {
      left: 'unset',
    },
    float: 'right',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  menuDetails: {
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleSpace: {
    '& h2': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      '& ::before': {
        content: 'unset',
      },
      '& ::after': {
        content: 'unset',
      },
    },
  },
});
interface CategoryDialogProps {
  categoryId: string;
  dragFor: 'categories' | 'items';
  title: string;
  text: string;
}
type Data = { name: string; img: string; id: string };

const DragDialog = React.forwardRef((props: CategoryDialogProps, ref) => {
  const { dragFor, categoryId, title } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { categories, reorderCategory } = useContext(CategoryContext);
  const [t, i18n] = useTranslation();
  const dir = i18n.dir();
  const { getItemsByCategory, reorderItem, fetchItems } =
    useContext(ItemsContext);
  const getData = () => {
    let formattedData: Data[] = [];
    if (dragFor === 'items') {
      const items = getItemsByCategory(categoryId);
      items.map((item) => {
        const name = translation(item.name).getTranslationValue(i18n.language);
        const img = _.isEmpty(item.images)
          ? ''
          : getCloudinaryImageURL(item.images[0].public_id);
        formattedData.push({ name, img, id: item.id });
      });
    } else {
      categories.map((category) => {
        const name = translation(category.name).getTranslationValue(
          i18n.language
        );
        formattedData.push({ name, img: '', id: category.id });
      });
    }
    return formattedData;
  };
  const [data, setData] = useState<Data[]>([]);

  const handelOpenClose = () => {
    setOpen(!open);
    setData(getData());
  };

  const Row = (props: {
    name: string;
    img: string;
    index: number;
    id: string;
  }) => {
    const { name, img, index, id } = props;
    return (
      <DraggableItem index={index} draggableId={id}>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={img}>
              <Icon
                icon={dragFor === 'items' ? 'Fastfood' : 'CategoryOutlined'}
              />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={name} secondary={index + 1} />
        </ListItem>
      </DraggableItem>
    );
  };
  const reorder = (drop: DropResult) => {
    const result = reorderBasedOnDraggableResult(drop, data);
    setData(result);
  };
  const handleClickSave = () => {
    if (dragFor === 'items') {
      reorderItem(data);
    } else {
      reorderCategory(data)
        .then((res) => {
          console.log('reorder cateogry res', res);
          fetchItems();
        })
        .catch((err) => {
          console.error('reorder category error', err);
        });
    }
    handelOpenClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handelOpenClose}
        maxWidth="md"
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          dir={dir}
          className={classes.titleSpace}
          id="alert-dialog-title"
        >
          {title}
        </DialogTitle>
        <ContentWrapper>
          <List style={{ width: '100%' }}>
            <DraggableList
              droppableId={dragFor}
              onDragEnd={reorder}
              direction="vertical"
            >
              {data.map((info, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Row
                      id={info.id}
                      img={info.img}
                      name={info.name}
                      index={index}
                    />
                  </Grid>
                );
              })}
            </DraggableList>
          </List>
        </ContentWrapper>
        <DialogActions>
          <Button
            style={{
              padding: '.4rem 1rem',
              borderRadius: 5,
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              border: 0,
              boxShadow: `0px 0px 20px 0px ${theme.palette.primary.main}`,
              cursor: 'pointer',
            }}
            color="primary"
            onClick={handleClickSave}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        startIcon={<Icon icon={'sort'} />}
        className={classes.button}
        onClick={handelOpenClose}
      >
        {props.text}
      </Button>
    </>
  );
});

export default DragDialog;
