import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import QRCode from 'react-qr-code';
import QRCode from 'qrcode.react';
import { useNotification, useWebsite } from 'hooks';
import { Button } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {},
}));

interface Props {
  children?: any;
  size: number;
  fileName: string;
  value: string;
  id: string;
}

const QRCodeGenerator = (props: Props): ReactElement => {
  const classes = useStyles();
  const { showSnackbar } = useNotification();
  const [t] = useTranslation();
  const { fileName, size, value, id } = props;

  const downloadQRCode = () => {
    try {
      // Generate download with use canvas and stream
      const canvas = document.getElementById(id);
      //@ts-ignore
      const pngUrl = canvas
        //@ts-ignore
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${fileName}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error('error while downloading QR Code', error);
      showSnackbar(t('error'), 'error');
    }
  };
  return (
    <div className={classes.root}>
      <QRCode
        id={id}
        value={value}
        size={size}
        level={'H'}
        includeMargin={true}
      />
      <Button
        fullWidth={true}
        onClick={downloadQRCode}
        endIcon={<SaveAltIcon />}
        color="primary"
        variant="outlined"
      >
        {t('download_QR_code')}
      </Button>
    </div>
  );
};

const defaultProps: Props = {
  size: 300,
  fileName: 'foodlee-qr-code',
  value: '',
  id: 'qr-gen',
};

QRCodeGenerator.defaultProps = defaultProps;

export default QRCodeGenerator;
