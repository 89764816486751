import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/functions';

const apiKey = process.env.REACT_APP_apiKey;
const authDomain = process.env.REACT_APP_authDomain;
const projectId = process.env.REACT_APP_projectId;
const storageBucket = process.env.REACT_APP_storageBucket;
const messagingSenderId = process.env.REACT_APP_messagingSenderId;
const appId = process.env.REACT_APP_appId;
const measurementId = process.env.REACT_APP_measurementId;

const config = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};

const app = firebase.initializeApp(config);
firebase.analytics();
if (window.location.hostname === 'localhost') {
  app.firestore().useEmulator('localhost', 8080);
  app.auth().useEmulator('http://localhost:9099');
  app.functions().useEmulator('localhost', 5001);
  app.storage().useEmulator('localhost', 6001);
}

export default app;
