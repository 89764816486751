import { paths } from 'constant/values';
import { pages } from 'constant/routes';

const { home, menu, store, settings } = pages;
const navItems: Array<navItemInterface> = [
  { key: home.title.toLowerCase(), title: home.title, to: paths.home },
  { key: menu.title.toLowerCase(), title: menu.title, to: paths.menu },
  { key: store.title.toLowerCase(), title: store.title, to: paths.store },
  {
    key: settings.title.toLowerCase(),
    title: settings.title,
    to: paths.settings,
  },
];
export interface navItemInterface {
  key: string;
  title: string;
  to: string;
}
export default navItems;
