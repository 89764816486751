import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import OTPTextFeild from './otp-text-field';
import { OTPProps } from './types';
import { Button } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
  content: {
    margin: 10,
  },
}));

interface Props extends OTPProps {
  children?: any;
  trigger?: ReactElement;
  shouldOpen: boolean;
  withTrigger: boolean;
  preventClose: boolean;
  onClose: () => any;
}

const OTPDialog = (props: Props): ReactElement => {
  const classes = useStyles();
  const {
    trigger,
    onVerfiySuccess,
    onClose,
    withTrigger,
    shouldOpen,
    preventClose,
    ...rest
  } = props;
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpenClose = () => {
    const newState = !open;
    if (preventClose === false) {
      setOpen(newState);
      if (newState === false) {
        onClose();
      }
    }
  };
  useEffect(() => {
    setOpen(shouldOpen);
  }, [shouldOpen]);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleOpenClose}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>{t('verify_your_phone_number')}</DialogTitle>
        <DialogContent className={classes.content}>
          <OTPTextFeild
            {...rest}
            onVerfiySuccess={(phone) => {
              onVerfiySuccess(phone);
              setOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
      {withTrigger ? (
        <Button onClick={handleOpenClose}>{t('verify')}</Button>
      ) : null}
    </>
  );
};

const defaultProps: Props = {
  onVerfiySuccess: () => undefined,
  onVerfiyError: () => undefined,
  phone: '',
  shouldOpen: false,
  withTrigger: true,
  preventClose: false,
  onPhoneChange: () => undefined,
  onClose: () => undefined,
};

OTPDialog.defaultProps = defaultProps;

export default OTPDialog;
