import { Name } from 'interfaces copy/common';
import joi from 'joi';
import _ from 'lodash';
import { name } from 'validations/default-values';
// const nameSchema = joi
//   .object({
//     default: joi.string().default('').allow(''),
//     defaultLanguage: {
//       language: joi.string().required(),
//       languageCode: joi.string().required(),
//     },
//     translations: joi.array().default([]),
//   })
//   .default(name)
//   .options({ stripUnknown: true });

export const checkNameObject = (name: Name) => {
  let isValid = false;
  const hasDefaultValue = !_.isEmpty(name.default);
  const hasTranslation = !_.isEmpty(name.translations[0]?.translation);
  if (hasDefaultValue || hasTranslation) isValid = true;
  console.log('name', name, isValid);
  return isValid;
};

const nameSchema = joi
  .object({
    default: joi.string().default('').trim().allow(''),
    defaultLanguage: {
      language: joi.string().required(),
      languageCode: joi.string().required(),
    },
    translations: joi
      .array()
      .items(
        joi.object({
          language: joi.string().required(),
          languageCode: joi.string().required(),
          translation: joi.string().default('').trim().allow(''),
        })
      )
      .default([]),
  })
  .default(name)
  .custom((value, helpers) => {
    const isValied = checkNameObject(value);
    if (isValied) return value;
    return helpers.error('name is invalid');
  })
  .options({ stripUnknown: true });
export default nameSchema;
