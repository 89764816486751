import { createTimestamp } from 'utils/dates';
import { name, description } from 'validations/default-values';
import { Item } from 'interfaces';
import getDefaultStore from '../store';

const item = (): Item => {
  const store = getDefaultStore();
  return {
    id: '',
    name: name,
    currency: 'SAR',
    description: description,
    rating: 0,
    price: 0,
    store: {
      categories: store.categories,
      logo: store.logo,
      name: store.name,
      storeId: store.storeId,
      type: store.type,
    },
    itemIndex: 9999,
    categoryIndex: 9999,

    images: [],
    createdAt: createTimestamp(),
    modifiedAt: createTimestamp(),
    isLive: false,
    identifiers: [],
    categories: [],
    keywords: [],
    addons: [],
    information: {
      calories: 0,
    },
    variants: [],
    websiteConfig: {},
  };
};

export default item;
