import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { paths } from 'constant/values';
import firebase from 'firebase-app';
import _ from 'lodash';
import { isUserSignedIn } from 'services/user';
import { UserContext } from 'context';
import LazyLoader from 'components/lazy-loader';
const RouteWithLayout = (props) => {
  const {
    isProtected,
    layout: Layout,
    component: Component,
    layoutTitle,
    ...rest
  } = props;

  const isLoggedIn = useContext(UserContext).isLoggedIn;

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (isLoggedIn || isProtected === false) {
          return (
            <Layout layoutTitle={layoutTitle} {...matchProps}>
              <LazyLoader>
                <Component {...matchProps} />
              </LazyLoader>
            </Layout>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: paths.signIn,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default RouteWithLayout;
