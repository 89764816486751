export default {
  // public routes
  DOMAIN: process.env.REACT_APP_DOMAIN,
  signIn: '/signin',
  signup: '/signup',
  resetPassword: '/reset-password',
  emailVerification: '/email-verification',

  // private routes
  home: '/home',
  dashboard: '/dashboard',
  store: '/store',
  settings: '/settings',
  menu: '/menu',
  contact: '/contact-us',
};
