import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  sendOTPVerficationCodeToPhoneNumber,
  verifyOTPCode,
} from 'services/user';
import { OTPProps } from './types';
import { useNotification } from 'hooks';
import { UserLocationContext } from 'context';
import _ from 'lodash';
import { phoneSchema } from 'validations/common';
import { useTranslation } from 'react-i18next';
import PhoneIcon from '@material-ui/icons/Phone';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditIcon from '@material-ui/icons/Edit';
import LoadingScreen from 'components/loading-screen';
import clsx from 'clsx';
import ReplayIcon from '@material-ui/icons/Replay';
//import {} from 'components'
const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
  phone: {
    color: theme.palette.info.main,
  },
  hide: {
    visibility: 'hidden',
  },
  loading: {
    justifyContent: 'center',
  },
  helperText: {
    margin: 10,
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface Props extends OTPProps {
  children?: any;
}

const OTPTextFeild = (props: Props): ReactElement => {
  const classes = useStyles();
  const {
    phone: defaultPhone,
    onVerfiySuccess,
    onVerfiyError,
    onPhoneChange,
  } = props;
  const [code, setCode] = useState('');
  const { country } = useContext(UserLocationContext);
  const [phone, setPhone] = useState(defaultPhone);
  const [editPhone, setEditPhone] = useState(false);
  const [lastSendPhone, setLastSendPhone] = useState('');
  const [t] = useTranslation();
  const [status, setStatus] =
    useState<
      | 'in_active'
      | 'sending_verification_code'
      | 'verifying'
      | 'resend_success'
      | 'verified'
      | 'error'
      | 'invalid_verification_code'
      | 'phone_is_already_in_use'
      | 'too_many_otp_requests_try_later'
    >('in_active');
  const isFetching =
    status === 'sending_verification_code' || status === 'verifying';
  const onCodeChange = (newCode: string) => {
    setCode(newCode);
  };
  const { showSnackbar } = useNotification();
  useEffect(() => {
    if (status === 'resend_success') {
      showSnackbar(t('verification_code_has_been_sent'), 'info');
    }
    if (status === 'verified') {
      showSnackbar(t('verified'), 'success');
    }
    if (status === 'phone_is_already_in_use') {
      showSnackbar(t('phone_is_already_in_use'), 'error');
    }
    if (status === 'error') {
      showSnackbar(t('error'), 'error');
    }
  }, [status]);
  const checkPhoneValue = (phoneToCheck = phone) => {
    const validation = phoneSchema.validate(phoneToCheck);
    const value: string = validation.value;
    const isValid = validation.error === undefined;
    return { value, isValid };
  };
  const isValidPhone = checkPhoneValue().isValid;
  useEffect(() => {
    if (isValidPhone) {
      resend();
    }
  }, []);

  const resend = async () => {
    const validation = checkPhoneValue();
    console.log('validation', validation);
    if (validation.isValid) {
      const validatedPhone = validation.value;
      setStatus('sending_verification_code');
      setCode('');
      setPhone(validatedPhone);
      setLastSendPhone(validatedPhone);
      const result = await sendOTPVerficationCodeToPhoneNumber(validatedPhone);
      console.log('resend result', result);
      if (result.message === 'ok') {
        setStatus('resend_success');
      } else {
        setStatus(result.message);
        showSnackbar(t('too_many_otp_requests_try_later'), 'error');
      }
    } else {
      showSnackbar(t('phone_number_is_incorrect'), 'error');
    }
  };
  const verfiy = async () => {
    if (isValidPhone) {
      setStatus('verifying');
      const result = await verifyOTPCode(code);
      console.log('success', result);
      if (result.success) {
        setStatus('verified');
        onVerfiySuccess(phone);
      } else {
        if (result.message === 'error') {
          setStatus('error');
        }
        if (result.message === 'phone_is_already_in_use') {
          setStatus('phone_is_already_in_use');
        }
        if (
          result.message === 'invalid_verification_code' ||
          result.message === 'missing_verification_code'
        ) {
          setStatus('invalid_verification_code');
        }
        onVerfiyError(phone);
      }
    } else {
      showSnackbar(t('phone_number_is_incorrect'), 'error');
    }
  };

  const HelperText = () => {
    return (
      <div className={classes.helperText}>
        {isFetching ? (
          <LoadingScreen
            active={true}
            className={classes.loading}
            loadingType="inline"
            text={t(status)}
          />
        ) : (
          <Typography variant="caption" color="textSecondary">
            {status === 'resend_success'
              ? t('check_your_phone_for_the_verification_code')
              : t(status)}
          </Typography>
        )}
      </div>
    );
  };
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={editPhone ? 12 : undefined}>
        {editPhone ? (
          <MuiPhoneNumber
            fullWidth={true}
            variant="outlined"
            value={phone}
            autoFocus={true}
            onBlur={() => {
              setEditPhone(false);
              if (lastSendPhone !== phone) {
                resend();
              }
            }}
            defaultCountry={country.code.toLowerCase()}
            onChange={(phone) => {
              setPhone(phone);
              const validated = checkPhoneValue(phone).value;
              onPhoneChange(validated);
            }}
          />
        ) : (
          <Button
            endIcon={<EditIcon />}
            className={classes.phone}
            onClick={() => {
              setEditPhone(true);
            }}
          >
            {isValidPhone ? phone : t('add_your_phone_number')}
          </Button>
        )}
      </Grid>

      <Grid item xs={12}>
        <TextField
          label={t('verification_code')}
          value={code}
          placeholder="123456"
          helperText={<HelperText />}
          fullWidth={true}
          variant="outlined"
          size="medium"
          onChange={(e) => onCodeChange(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          fullWidth
          className={clsx({
            [classes.hide]: isFetching,
          })}
          onClick={verfiy}
          color="primary"
          variant="outlined"
        >
          {t('verify')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          hidden={true}
          endIcon={<ReplayIcon />}
          className={clsx({
            [classes.hide]: isFetching,
          })}
          onClick={resend}
        >
          {t('resend')}
        </Button>
      </Grid>
    </Grid>
  );
};

const defaultProps: Props = {
  onVerfiySuccess: () => undefined,
  onVerfiyError: () => undefined,
  onPhoneChange: () => undefined,
  phone: '',
};

OTPTextFeild.defaultProps = defaultProps;

export default OTPTextFeild;
