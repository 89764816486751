import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CopyToClipboard from './copy-to-clipboard';
import { useWebsite } from 'hooks';
import { useTranslation } from 'react-i18next';
import LinkIcon from '@material-ui/icons/Link';
const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
}));

interface Props {
  children?: any;
}

const CopyStoreURL = (props: Props): ReactElement => {
  const classes = useStyles();
  const {} = props;
  const { storeURL } = useWebsite();
  const [t] = useTranslation();
  return (
    <CopyToClipboard
      value={storeURL}
      text={t('copy_store_link')}
      btn={{
        fullWidth: true,
        endIcon: <LinkIcon />,
        variant: 'outlined',
      }}
    />
  );
};

const defaultProps: Props = {};

CopyStoreURL.defaultProps = defaultProps;

export default CopyStoreURL;
