import * as schema from './schema';
import validationResponse from './validation-response';
import { Category, Store, User } from 'interfaces';
import { Item } from 'interfaces';

export const userSchemaValidation = (user?: Partial<User>) => {
  const result = schema.userSchema.validate(user);
  return validationResponse(result);
};

export const storeSchemaValidation = (store: Store) => {
  const result = schema.storeSchema.validate(store);
  return validationResponse(result);
};
export const emailValidateSchema = (email: string) => {
  const result = schema.emailSchema.validate(email);
  return validationResponse(result);
};

export const itemSchemaValidation = (item: Item) => {
  const result = schema.itemSchema.validate(item);
  return validationResponse(result);
};
export const categorySchemaValidation = (category: Category) => {
  const result = schema.categorySchema.validate(category);
  return validationResponse(result);
};
