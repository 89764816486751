import joi from 'joi';

import { nameSchema } from 'validations/common';
import { name } from 'validations/default-values';
const descriptionSchema = joi
  .object({
    default: joi.string().optional().allow(''),
    defaultLanguage: {
      language: joi.string().allow(''),
      languageCode: joi.string().allow(''),
    },
    translations: joi.array().default([]),
  })
  .default(name)
  .options({ stripUnknown: true });
export default descriptionSchema;
