import { ReactElement, Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LoadingScreen from './loading-screen';
import CustomErrorBoundary from './error-boundary-custom';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
}));

interface Props {
  children?: any;
}

const LazyLoader = (props: Props): ReactElement => {
  const classes = useStyles();
  const { children } = props;
  return (
    <CustomErrorBoundary>
      <Suspense
        fallback={<LoadingScreen active={true} withBackgroudColor={false} />}
      >
        {children}
      </Suspense>
    </CustomErrorBoundary>
  );
};

const defaultProps: Props = {};

LazyLoader.defaultProps = defaultProps;

export default LazyLoader;
