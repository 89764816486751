import React, { useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import theme from 'theme';
import {
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  styled,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import AddImage from 'components/add-image';
import { Rating } from '@material-ui/lab';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useFormik } from 'formik';
import { Category, Item, MenuDialogValues, MultiCategory } from 'interfaces';
import { menuController } from 'controllers';
import * as Yup from 'yup';
import { createImageObjectURL } from 'utils';
import { StoreContext } from 'context/store';
import { item as itemDefault } from 'validations/default-values';
import { useTranslation } from 'react-i18next';
import {
  addFileCustomData,
  deleteStorage,
  uploadStorage,
} from 'services/storage';
import { ImageCropper, LoadingScreen } from 'components';
import _ from 'lodash';
import { CategoryContext, ItemsContext } from 'context';
import translation from 'hooks/name';

const ContentWrapper = styled(DialogContent)({
  display: 'flex',
  justifyContent: 'center',
  padding: '8px 24px 40px',
});
const FormWrapper = styled('form')({
  display: 'flex',
});

const useStyles = makeStyles((theme) => ({
  root: {},
  img: {
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
  },
  divider: {
    margin: '0 1rem',
    backgroundColor: `${theme.palette.primary.main}`,
    height: '110%',
    //visibility: 'hidden',
  },
  titleDivider: {
    width: '100%',
    margin: theme.spacing(1),
  },
  description: {
    width: '100%',
    marginTop: '3rem',
  },
  name: {
    marginTop: '1rem',
  },
  languageSwitch: {
    display: 'flex',
  },
  arabicText: {
    marginTop: '1rem',

    '& label': {
      left: 'unset',
    },
    float: 'right',
  },
  imgRemoveBtn: {
    marginTop: theme.spacing(1),
    textTransform: 'initial',
    color: theme.palette.error.light,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    // marginBottom: '2rem',
    marginTop: '1rem',
    height: 250,
  },
  menuDetails: {
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleSpace: {
    '& h2': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      '& ::before': {
        content: 'unset',
      },
      '& ::after': {
        content: 'unset',
      },
    },
  },
  information: {
    margin: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(7),
  },
}));
interface MenuDialogProps {
  title: string;
  edit: boolean;
  setAlert: (state: boolean) => void;
  setOperationSuccess: (state: boolean) => void;
  setAlertMessage: (state: string) => void;
}
const MenuDialog = React.forwardRef((props: MenuDialogProps, ref) => {
  const { edit, title, setAlert, setOperationSuccess, setAlertMessage } = props;
  const { store } = React.useContext(StoreContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [menuID, setMenuID] = React.useState('');
  const currency = store.currency;
  const { categories, findCategory } = useContext(CategoryContext);
  const [imageAsFile, setImageAsFile] = React.useState<any>('');
  const [itemBefore, setItemBefore] = React.useState<Item>(itemDefault());
  const [lodaing, setLodaing] = React.useState(false);
  const [removeImage, setRemoveImage] = React.useState(false);
  const {
    findItemById,
    getItemsByCategory,
    updateItemIndexesAfterCategoryChange,
    fetchItems,
    deleteItem,
  } = useContext(ItemsContext);
  const hiddenFileInput: any = React.useRef();
  const [t, i18n] = useTranslation();

  React.useEffect(() => {
    formik.initialTouched = false;
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
    setImageAsFile('');
  };
  React.useImperativeHandle(ref, () => ({
    showAlert() {
      formik.setValues(formik.initialValues);
      handleClickOpen();
    },
    editMenu(menu: Item) {
      setValues(menu);
      const savedItem = findItemById(menu.id).item;
      if (savedItem) {
        setItemBefore(savedItem);
      }

      handleClickOpen();
    },
  }));
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    handleClose();
    await deleteImage();
    deleteItem(itemBefore).then(handleAlert);
  };
  useEffect(() => {
    //runBatchUpLoad();
  });
  const setValues = (menu: Item) => {
    //@ts-ignore
    setMenuID(menu.id);
    formik.setFieldValue('imageURL', menu?.images[0]?.url);
    formik.setFieldValue('public_id', menu?.images[0]?.public_id);
    formik.setFieldValue('category', menu?.categories[0]?.id);
    formik.setFieldValue('isLive', menu.isLive);
    formik.setFieldValue('price', menu.price);
    formik.setFieldValue('rating', menu.rating);
    formik.setFieldValue('nameEnglish', menu.name.default);
    formik.setFieldValue('nameArabic', menu.name.translations[0].translation);
    formik.setFieldValue('descriptionEnglish', menu.description.default);
    formik.setFieldValue(
      'descriptionArabic',
      menu.description.translations[0].translation
    );
    formik.setFieldValue('calories', menu.information.calories);
  };
  const handleAlert = (response: { success: boolean; message: string }) => {
    setAlert(true);
    setLodaing(false);
    setOperationSuccess(response.success);
    setAlertMessage(response.message);
    setOpen(!response.success);
    //fetchItems();
  };
  const MenuSchema = Yup.object().shape(
    {
      price: Yup.number().min(0, 'Price cannot be negative'),
      nameEnglish: Yup.string().when('nameArabic', {
        is: (fieldTwo: string) => !fieldTwo || fieldTwo.length === 0,
        then: Yup.string().required('At least one of the fields is required'),
      }),
      nameArabic: Yup.string().when('nameEnglish', {
        is: (fieldOne: string) => !fieldOne || fieldOne.length === 0,
        then: Yup.string().required('At least one of the fields is required'),
      }),
      descriptionEnglish: Yup.string(),
      descriptionArabic: Yup.string(),
      imageURL: Yup.string(),
    },
    [['nameEnglish', 'nameArabic']]
  );
  const formik = useFormik({
    initialValues: {
      category: '',
      isLive: true,
      price: 0,
      rating: 0,
      nameEnglish: '',
      nameArabic: '',
      descriptionEnglish: '',
      descriptionArabic: '',
      imageURL: '',
      public_id: '',
      calories: 0,
    },
    validateOnMount: true,
    validationSchema: MenuSchema,
    onSubmit(values) {
      console.log('menu values', values);
      setLodaing(true);
      handleClose();

      if (imageAsFile) {
        handleImageUpload(values);
      } else if (removeImage) {
        deleteImage();
        handleMenuSubmit(values, '', '');
      } else {
        handleMenuSubmit(values, values.imageURL, values.public_id);
      }
    },
  });
  const handleMenuSubmit = (
    values: any,
    imgUrl: string = '',
    public_Id: string
  ) => {
    if (imgUrl) {
      updateMetaData(public_Id);
    }
    const category = getSelectedCategoryInfo();
    const hasCateogry = !_.isEmpty(category);
    const categoryId = hasCateogry ? category[0].id : '';
    const numberOfItemsInCategory = getItemsByCategory(categoryId).length;
    const cateogryIndex = findCategory(categoryId).index;
    const indexes = {
      item: hasCateogry ? numberOfItemsInCategory : 9999,
      category: cateogryIndex === -1 ? 9999 : cateogryIndex,
    };
    if (edit) {
      menuController
        .editMenuController(
          values,
          imgUrl,
          public_Id,
          menuID,
          store,
          itemBefore,
          category,
          indexes
        )
        .then((res) => {
          const categoryHasChanged = !_.isEqual(
            category,
            itemBefore.categories
          );
          if (categoryHasChanged) {
            updateItemIndexesAfterCategoryChange(itemBefore);
          } else {
            fetchItems(true);
          }
          handleAlert(res);
        });
    } else {
      menuController
        .addMenuController(values, imgUrl, public_Id, store, category, indexes)
        .then((res) => {
          fetchItems(true);
          handleAlert(res);
        });
    }
  };
  const getSelectedCategoryInfo = (): MultiCategory => {
    const currentCategoryId = formik.values.category;
    const category = categories.find(
      (category) => category.id === currentCategoryId
    );
    if (category) {
      return [{ id: category.id, name: category.name }];
    }
    return [];
  };
  const deleteImage = async () => {
    const prevImages = itemBefore.images[0];
    const hasImageBefore = !_.isEmpty(prevImages);
    if (hasImageBefore) {
      const imageUrlToDelete = prevImages.public_id;
      await deleteStorage(imageUrlToDelete);
    }
  };
  const imageMetaData = () => {
    let category = categories.find((cateogryInfo) => {
      return cateogryInfo.id === formik.values.category;
    });
    let categoryToInclude = {};
    if (category) {
      categoryToInclude = {
        categoryAr: category.name.translations[0].translation,
        categoryEn: category.name.default,
      };
    }
    return {
      nameAr: formik.values.nameArabic,
      nameEn: formik.values.nameEnglish,
      price: formik.values.price,
      currency: currency,
      ...categoryToInclude,
    };
  };

  const updateMetaData = async (url: string) => {
    const metadata = imageMetaData();
    await addFileCustomData(url, metadata);
  };
  const handleImageUpload = async (values: MenuDialogValues) => {
    const success = async (data: { url: string; public_id: string }) => {
      await deleteImage();
      handleMenuSubmit(values, data.url, data.public_id);
    };

    const error = (msg: string) => {
      setAlertMessage('err with image');
      setAlert(true);
      setLodaing(false);
    };
    await uploadStorage(imageAsFile, 'menu-itmes', success, error);
  };
  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };
  const handleImageAsFile = async (e: any) => {
    const image = e.target.files[0];
    if (image) {
      const objectUrl = await createImageObjectURL(image);
      if (objectUrl.success) {
        formik.setFieldValue('imageURL', objectUrl.url);
        setImageAsFile(objectUrl.file);
        setRemoveImage(false);
        setAlert(false);
        setAlertMessage('');
      } else {
        setAlert(true);
        setAlertMessage(objectUrl.massage);
      }
    }
  };
  const handleClickRemoveImage = () => {
    setRemoveImage(true);
    formik.setFieldValue('imageURL', undefined);
    setImageAsFile(undefined);
  };
  const Title = (props: { lable: string }) => {
    return (
      <>
        {/* <Typography className={classes.title} variant="subtitle1">
          Information
        </Typography> */}
        <Divider className={classes.titleDivider} orientation="horizontal" />
      </>
    );
  };

  return (
    <>
      <LoadingScreen active={lodaing} />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form className="baseForm" onSubmit={formik.handleSubmit} noValidate>
          <DialogTitle className={classes.titleSpace} id="alert-dialog-title">
            {title}
            <div>
              <Select
                value={formik.values.category}
                name="category"
                onChange={formik.handleChange}
                displayEmpty
                // className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="">{t('selectCategory')}</MenuItem>
                {categories.map((item: Category) => (
                  <MenuItem key={item.id} value={item.id}>
                    {translation(item.name).getAnyTranslationValue(
                      i18n.language
                    )}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <FormControlLabel
              style={{ float: 'right' }}
              control={
                <Switch
                  checked={formik.values.isLive}
                  onChange={formik.handleChange}
                  name="isLive"
                  color="primary"
                />
              }
              label={t('live')}
            />
          </DialogTitle>
          <input
            type="file"
            onChange={handleImageAsFile}
            ref={hiddenFileInput}
            style={{ display: 'none' }}
          />
          {/* {formik.values.imageURL ? (
          <Button onClick={handleClick}>Change image</Button>
        ) : null} */}

          <div className={classes.imageContainer}>
            {formik.values.imageURL ? (
              <>
                <img
                  onClick={handleClick}
                  src={formik.values.imageURL}
                  alt="menu-item"
                  className={classes.img}
                />
              </>
            ) : (
              <AddImage onClick={handleClick} />
            )}
          </div>

          <span style={{ display: 'flex', justifyContent: 'center' }}></span>
          {formik.values.imageURL ? (
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  className={classes.imgRemoveBtn}
                  onClick={handleClickRemoveImage}
                >
                  {t('removeImage')}
                </Button>
              </Grid>
            </Grid>
          ) : null}
          <div className={classes.menuDetails}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '17rem',
              }}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
                style={{ maxWidth: '12rem' }}
                value={formik.values.price}
                name="price"
                onChange={formik.handleChange}
                required
              />

              {formik.errors.price && (
                <Typography variant="caption" color="error">
                  {formik.errors.price}
                </Typography>
              )}
            </div>

            <Rating name="read-only" value={formik.values.rating} readOnly />
          </div>
          <ContentWrapper>
            <Divider className={classes.divider} orientation="vertical" />

            <div>
              <TextField
                className={classes.name}
                label={t('english')}
                name="nameEnglish"
                value={formik.values.nameEnglish}
                onChange={formik.handleChange}
                fullWidth={true}
              />
              {formik.errors.nameEnglish && formik.touched.nameEnglish && (
                <Typography variant="caption" color="error">
                  {formik.errors.nameEnglish}
                </Typography>
              )}
              <TextField
                className={classes.description}
                variant={'outlined'}
                value={formik.values.descriptionEnglish}
                label={t('descriptionInEnglish')}
                name="descriptionEnglish"
                minRows={4}
                multiline={true}
                onChange={formik.handleChange}
              />
            </div>
            <Divider className={classes.divider} orientation="vertical" />
            <div>
              <TextField
                fullWidth={true}
                className={classes.arabicText}
                value={formik.values.nameArabic}
                label={t('arabic')}
                name="nameArabic"
                dir="rtl"
                onChange={formik.handleChange}
              />
              {formik.errors.nameArabic && formik.touched.nameArabic && (
                <Typography variant="caption" color="error">
                  {formik.errors.nameArabic}
                </Typography>
              )}
              <TextField
                className={classes.description}
                variant={'outlined'}
                value={formik.values.descriptionArabic}
                label={t('descriptionInArabic')}
                minRows={4}
                name="descriptionArabic"
                multiline={true}
                dir="rtl"
                onChange={formik.handleChange}
              />
            </div>
          </ContentWrapper>
          <Title lable={'Information'} />
          <ContentWrapper>
            <Divider className={classes.divider} orientation="vertical" />
            <div>
              <TextField
                className={classes.information}
                label={t('calories')}
                name="calories"
                type="number"
                value={formik.values.calories}
                onChange={formik.handleChange}
                //fullWidth={true}
              />
            </div>
          </ContentWrapper>
          <DialogActions>
            {edit && (
              <Button
                style={{
                  padding: '.4rem 1rem',
                  marginRight: 'auto',
                  borderRadius: 5,
                  border: 0,
                  cursor: 'pointer',
                }}
                onClick={handleDelete}
                color="secondary"
              >
                {t('delete')}
              </Button>
            )}
            <Button onClick={handleClose} color="primary">
              {t('cancel')}
            </Button>
            <Button
              style={{
                padding: '.4rem 1rem',
                borderRadius: 5,
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                border: 0,
                boxShadow: `0px 0px 20px 0px ${theme.palette.primary.main}`,
                cursor: 'pointer',
              }}
              type="submit"
              color="primary"
            >
              {t('save')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
});

export default MenuDialog;
