import React, { useContext } from 'react';
import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { NavLink } from 'react-router-dom';
import navItems, { navItemInterface } from './common';
import Logo from '../../assets/logo/foodlee-color1.svg';
import { StoreContext } from 'context/store';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'black',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative',
    marginBottom: '2rem',
    marginTop: theme.spacing(1.5),
  },
  toolbar: {
    padding: '0 10rem',
  },
  logo: {
    flex: 1,
    '& img': {
      width: 150,
    },
  },
  navItems: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    '& a': {
      textDecoration: 'none',
      fontWeight: 700,
      color: '#787878',
    },
  },
  itemSelected: {
    color: '#383838 !important',
    fontSize: 'large !important',
    '& hr': {
      display: 'block !important',
      border: `2px solid ${theme.palette.primary.main}`,
      margin: '4px 11px',
      borderRadius: 10,
    },
  },
  navItem: {
    position: 'relative',
    marginTop: 10,
    paddingBottom: '10px',
  },
}));

function NavBar() {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const { store } = useContext(StoreContext);
  const NavItems = (props: any) => {
    return (
      <NavLink
        to={props.to}
        className={classes.navItem}
        activeClassName={classes.itemSelected}
      >
        {props.children}
        <hr style={{ display: 'none' }} />
      </NavLink>
    );
  };
  return (
    <StoreContext.Consumer>
      {(value) => (
        <AppBar position="fixed" className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.logo}>
              {/*<a href={link} target="_blank">
                 {t('visitStore')}
               </a> 
              */}
              <img src={Logo} style={{ maxWidth: 100 }} />
            </div>
            <div className={classes.navItems}>
              {navItems.map((nav: navItemInterface) => (
                <NavItems key={nav.key} to={nav.to}>
                  {t(nav.title)}
                </NavItems>
              ))}
            </div>
          </Toolbar>
        </AppBar>
      )}
    </StoreContext.Consumer>
  );
}

export default NavBar;
