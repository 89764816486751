import { Name, Description } from 'interfaces/common';

const name: Name = {
  default: '',
  defaultLanguage: {
    language: 'arabic',
    languageCode: 'ar',
  },
  translations: [],
};

export const description: Description = name;

export default name;
