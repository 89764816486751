import React, { ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { Icon } from 'components';
import {
  ColorProps,
  SizesProps,
  MenuItemsProps,
} from 'components/props';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

interface Props {
  icon?: ReactElement;
  size?: SizesProps;
  color?: ColorProps | 'inherit';
  text: string;
  variant?: 'contained' | 'outlined' | 'text';
  items?: MenuItemsProps;
  onClick: (index: number) => void;
}

const ButtonMenu = (props: Props) => {
  const { variant, color, text, size, icon, items, onClick } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant={variant}
        color={color}
        size={size}
        startIcon={icon}
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {text}
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {items?.map((item, index) => {
          if (item.icon) {
            return (
              <MenuItem onClick={() => {item.onClick(); handleClose()}} key={index}>
                <ListItemIcon>
                  <Icon icon={item.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                />
              </MenuItem>
            );
          }
          return (
            <MenuItem onClick={() => item.onClick()} key={index}>
              <ListItemText
                primary={item.text}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

const defaultProps: Props = {
  variant: 'contained',
  color: 'primary',
  onClick: () => undefined,
  text: 'Menu',
  size: 'large',
  icon: <Icon icon="Add" />,
  items: [],
};

ButtonMenu.defaultProps = defaultProps;

export default ButtonMenu;
