type detail = {
  context: {
    key: string;
    label: string;
  };
  message: string;
};

const formulateMessage = (message: string) => {
  return message;
};

const response = (validationResult: any) => {
  const error = validationResult.error;
  if (error) {
    let message = formulateMessage(error.message);
    let objectKey = error.details[0].context.key;
    let keysWithMessages = error.details.map((detail: detail) => {
      return { key: detail.context.label, message: detail.message };
    });
    return { isValid: false, message, key: objectKey, keys: keysWithMessages };
  } else {
    const data = validationResult.value;
    return { isValid: true, data };
  }
};

export default response;
