import { createTimestamp } from 'utils/dates';
import { User } from 'interfaces';
import name from '../name';

const user: User = {
  name: name,
  userObjectId: '',
  phone: '',
  docId: '',
  createdAt: createTimestamp(),
  isVerified: false,
  email: '',
  lastSignedIn: createTimestamp(),
  accountType: ['business'],
  storesAccess: [],
};
export default user;
