import React, { ReactElement, useEffect, useState } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { iconList } from './props';
import { Icon } from 'components';
import { InputAdornment } from '@material-ui/core';
import { ValidationResult } from 'joi';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
}));
type Validator = (value: any) => ValidationResult;
interface Props {
  children?: any;
  initialValue: string;
  textFieldProps: TextFieldProps;
  icon?: iconList;
  schema: { validate: Validator };
  onValid: (value: string) => any;
  errorMsg?: string;
  required: 'yes' | 'no';
}

const ValidatedTextFeild = (props: Props): ReactElement => {
  const classes = useStyles();
  const {
    icon,
    initialValue,
    schema,
    onValid,
    textFieldProps,
    errorMsg,
    required,
  } = props;
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState({ hasError: false, msg: '' });
  const [t] = useTranslation();
  const hasError = error.hasError;
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const validateValue = (valueToValidate = value) => {
    const result = schema.validate(valueToValidate);
    console.log('result', result);
    let isValid = result.error === undefined;
    const validatedValue = result.value;
    return { isValid, value: validatedValue };
  };
  const onComplete = () => {
    const validation = validateValue();
    console.log('validation', validation);
    const validValue = validation.value;
    const updateValue = (value: string) => {
      onValid(value);
      setValue(value);
    };
    if (validation.isValid) {
      updateValue(validValue);
    } else {
      const hasValue = !isEmpty(validValue);
      if (required === 'yes' || hasValue) {
        updateError(true);
      } else {
        updateValue('');
      }
    }
  };
  const updateError = (hasError: boolean) => {
    let msg = '';
    if (hasError) {
      msg = errorMsg || t('error');
    }
    setError({ hasError, msg });
  };
  const onChange = (value: string) => {
    setValue(value);
    if (error.hasError) {
      setError({ hasError: false, msg: '' });
    }
  };
  return (
    <TextField
      {...textFieldProps}
      InputProps={{
        startAdornment: icon ? (
          <InputAdornment position="start">
            <Icon icon={icon} />
          </InputAdornment>
        ) : undefined,
      }}
      value={value}
      error={hasError}
      onBlur={onComplete}
      onChange={(e) => onChange(e.target.value)}
      helperText={hasError ? error.msg : textFieldProps.helperText}
    />
  );
};

const defaultProps: Props = {
  textFieldProps: {},
  initialValue: '',
  required: 'no',
  schema: {
    validate: (value) => {
      return { value };
    },
  },
  onValid: () => undefined,
};

ValidatedTextFeild.defaultProps = defaultProps;

export default ValidatedTextFeild;
