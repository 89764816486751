import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ColorPicker, ColorButton } from 'material-ui-color';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
  reset: {
    textTransform: 'initial',
    //marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    right: 20,
  },
  resetEn: {
    right: 18,
  },
  resetAny: {
    right: 20,
  },
}));

interface Props {
  children?: any;
  value: string;
  defaultValue?: string;
  onColorChange: (hexColor: string) => void;
}

const CustomColorPricker = (props: Props): ReactElement => {
  const classes = useStyles();
  const { value, onColorChange, defaultValue } = props;
  const [colorValue, setColorValue] = useState<string | number>(value);
  const [hexColor, setHexColor] = useState<string>(value);
  const [t, i18n] = useTranslation();
  const firstRender = useRef(true);
  const currentLang = i18n.language;
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      onColorChange(hexColor);
    }
  }, [hexColor]);
  useEffect(() => {
    setColorValue(value);
  }, [value]);
  const defaultColor = defaultValue || '#4791db';
  return (
    <>
      <ColorPicker
        onChange={(color) => {
          setColorValue(color.value);
          setHexColor(`#${color.hex}`);
        }}
        value={colorValue}
        defaultValue={defaultColor}
        hideTextfield={true}
      />

      <Button
        onClick={() => setHexColor(defaultColor)}
        className={clsx(classes.reset, {
          [classes.resetEn]: currentLang === 'en',
          [classes.resetAny]: currentLang !== 'en',
        })}
        size="small"
      >
        {t('resetColor')}
      </Button>
    </>
  );
};

export default CustomColorPricker;
