import joi from 'joi';

const myCustomJoi = joi.extend(require('joi-phone-number'));
const phoneSchema = myCustomJoi
  .string()
  .phoneNumber({ defaultCountry: 'SA', format: 'e164' })
  .min(10)
  .max(20);
// .custom((value: string) => {
//   const isSaudiNumberContainZeroFirst = value.slice(0, 5) === '+9660';
//   if (isSaudiNumberContainZeroFirst) {
//     return value.replace('+9660', '+966');
//   }
//   return value;
// });

// const phoneSchema = joi
//   .string()
//   .min(6)
//   .max(20)
//   .default('')
//   .trim()
//   .custom((value: string) => {
//     const isSaudiNumberContainZeroFirst = value.slice(0, 5) === '+9660';
//     if (isSaudiNumberContainZeroFirst) {
//       return value.replace('+9660', '+966');
//     }
//     return value;
//   });
export default phoneSchema;
