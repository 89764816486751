import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import TranslateIcon from '@material-ui/icons/Translate';
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
  button: { position: 'absolute', top: '30px', right: '30px' },
}));

interface Props {
  children?: any;
  varaint: 'outlined' | 'text' | 'contained';

  topRight: boolean;
}

const Language = (props: Props): ReactElement => {
  const { varaint, topRight } = props;
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const lang = i18n.language;
  const languageHandler = () => {
    let newLang = '';
    if (lang === 'en') {
      newLang = 'ar';
    } else {
      newLang = 'en';
    }
    i18n.changeLanguage(newLang);
    localStorage.setItem('lang', newLang);
  };
  return (
    <Button
      className={clsx({
        [classes.button]: topRight,
      })}
      fullWidth={!topRight}
      variant={varaint}
      endIcon={<TranslateIcon />}
      onClick={languageHandler}
      size={topRight ? 'small' : 'large'}
    >
      {lang === 'en' ? 'عربي' : 'en'}
    </Button>
  );
};

const defaultProps: Props = {
  varaint: 'outlined',
  topRight: false,
};

Language.defaultProps = defaultProps;

export default Language;
