import firebase from 'firebase-app';
import { paths } from 'constant/values';
import firebaseAll from 'firebase';
import { Res, User, TempDocId } from 'interfaces';
import {
  saveUserInformation,
  onboardNewUser,
  isEmailAvailable,
} from './crud-user';
import { emailValidateSchema } from 'validations';
import { getCurrentUser } from 'services/common';
import _ from 'lodash';
const queryString = require('query-string');

const tempDocIdKey: TempDocId = 'tempdocid';

// sign in
export const signInUserWithEmailLink = async (email: string) => {
  type msg =
    | 'Email is not registered, please sign up'
    | 'email is not valid'
    | 'email is sent';
  const response: Res<undefined, msg> = {
    success: true,
    data: undefined,
    message: 'email is sent',
  };
  const validation = emailValidateSchema(email);
  if (validation.isValid) {
    const validatedEmail = validation.data;
    const emailNotRegistered = await isEmailAvailable(validatedEmail);
    if (emailNotRegistered) {
      response.success = false;
      response.message = 'Email is not registered, please sign up';
    } else {
      const linkResult = await signUpPasswordlessWithEmailLink(validatedEmail);
      response.success = linkResult.success;
    }
  } else {
    response.success = false;
    response.message = 'email is not valid';
  }

  return response;
};
export const signInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  const trimmedEmail = email.trim();
  type msg = 'ok' | 'password/email is wrong' | 'error';
  const response: Res<undefined, msg> = {
    data: undefined,
    message: 'ok',
    success: false,
  };
  return firebase
    .auth()
    .signInWithEmailAndPassword(trimmedEmail, password)
    .then((x) => {
      response.success = true;
      return response;
    })
    .catch((err) => {
      const errCode = err.code;
      response.success = false;
      if (
        errCode === 'auth/user-not-found' ||
        errCode === 'auth/wrong-password'
      ) {
        response.message = 'password/email is wrong';
      }
      console.log('err', err);
      return response;
    });
};

export const signUpWithEmailAndPassword = async (
  email: string,
  password: string,
  user?: User
) => {
  type msg =
    | 'ok'
    | 'user not found'
    | 'error while saving user information'
    | 'email is already used'
    | 'password must be more than 6 characters'
    | 'no user found'
    | 'error';
  const response: Res<undefined, msg> = {
    data: undefined,
    message: 'ok',
    success: false,
  };
  const loggedInUser = getCurrentUser();
  if (loggedInUser && user) {
    const trimmedEmail = email.trim();
    console.log('trimmedEmail', trimmedEmail);
    console.log('loggedInUser', loggedInUser);
    try {
      const emailResult = await loggedInUser.updateEmail(trimmedEmail);
      console.log('emailResult', emailResult);
      const passwordResult = await loggedInUser.updatePassword(password);
      await loggedInUser.updateProfile({
        displayName: user.name.default,
      });
      response.message = 'ok';
      response.success = true;

      console.log('passwordResult', passwordResult);
    } catch (error: any) {
      console.error('error while creating account', error);
      const errCode = error.code;
      if (errCode === 'auth/email-already-in-use') {
        response.message = 'email is already used';
      }
      if (errCode === 'auth/weak-password') {
        response.message = 'password must be more than 6 characters';
      }
    }
    return response;
  } else {
    response.message = 'no user found';
    return response;
  }
};
export const signUpPasswordlessWithEmailLink = async (
  email: string,
  user?: User
) => {
  const response: Res<undefined, string> = {
    success: false,
    message: '',
    data: undefined,
  };
  const trimmedEmail = email.trim();

  const actionCodeSettings = {
    // DO NOT REMOVE THE (&) AFTER THE (?) SO THAT WE CAN RETRIVE THE VALUE LATER ON IN (isSignedInWithEmailLink)

    url: `${paths.DOMAIN}${paths.emailVerification}?${tempDocIdKey}=${user?.docId}&${tempDocIdKey}=${user?.docId}`,
    // This must be true.
    handleCodeInApp: true,
  };
  return firebase
    .auth()
    .sendSignInLinkToEmail(trimmedEmail, actionCodeSettings)
    .then(async () => {
      window.localStorage.setItem('emailForSignIn', trimmedEmail);
      if (user) {
        const emailIsNotRegistered = await isEmailAvailable(trimmedEmail);
        if (emailIsNotRegistered) {
          const result = await saveUserInformation(user);
          response.success = result.success;
        } else {
          response.message = 'Email is already registered';
          response.success = false;
        }
      } else {
        response.success = true;
      }
      return response;
    })
    .catch((err) => {
      response.success = false;
      return response;
    });
};

// phone
export const verifyOTPCode = async (code: string) => {
  const response: Res<
    undefined,
    | 'error'
    | 'ok'
    | 'phone_is_already_in_use'
    | 'invalid_verification_code'
    | 'missing_verification_code'
  > = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    //@ts-ignore
    const result = await window.OTPConfirmationResult.confirm(code);
    console.log('otp result', result);
    response.success = true;
    response.message = 'ok';
  } catch (error: any) {
    console.log('error', error);
    const phoneNumberIsAlreadyUsed =
      error?.code === 'auth/credential-already-in-use';
    const missingCode = error?.code === 'auth/missing-verification-code';
    const inValidCode = error?.code === 'auth/invalid-verification-code';
    if (missingCode) {
      response.message = 'missing_verification_code';
    }
    if (inValidCode) {
      response.message = 'invalid_verification_code';
    }
    if (phoneNumberIsAlreadyUsed) {
      response.message = 'phone_is_already_in_use';
    }
  }
  return response;
};
// auth
export const sendOTPVerficationCodeToPhoneNumber = async (phone: string) => {
  const response: Res<
    string,
    'error' | 'ok' | 'too_many_otp_requests_try_later'
  > = {
    data: phone,
    message: 'error',
    success: false,
  };
  try {
    firebase.auth().useDeviceLanguage();
    const generateRecaptcha = async () => {
      //@ts-ignore
      const isRecaptchaCreated = !_.isEmpty(window.recaptchaVerifier);
      const isProduction = process.env.REACT_APP_CURRENT !== 'development';
      if (isRecaptchaCreated && isProduction) {
        try {
          //@ts-ignore - reset recaptchaVerifier to avoid errors & this code only works in production
          const widgetId = await window.recaptchaVerifier.render();
          //@ts-ignore grecaptcha is coming from the <script> tag in public/index.html
          grecaptcha.reset(widgetId);
        } catch (error) {
          console.error('error while resetting recaptcha', error);
          return;
        }
      } else {
        //@ts-ignore create new recaptchaVerifier
        window.recaptchaVerifier = new firebaseAll.auth.RecaptchaVerifier(
          'phone-number-button',
          {
            size: 'invisible',
          }
        );
      }
    };
    await generateRecaptcha();
    //@ts-ignore
    const appVerifier = window.recaptchaVerifier;
    console.log('appVerifier', appVerifier);
    const result = await firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier);
    console.log('phone result', result);
    //@ts-ignore
    window.OTPConfirmationResult = result;
    response.message = 'ok';
    response.success = true;
  } catch (error: any) {
    const tooManyRequest = error?.code === 'auth/too-many-requests';
    if (tooManyRequest) {
      response.message = 'too_many_otp_requests_try_later';
    }
    console.error('error while sending OTP code', error);
  }
  return response;
};

export const isUserSignedIn = () => {
  const user = firebase.auth().currentUser;

  if (user) return true;
  return false;
};
export const isSignedInWithEmailLink = async (url: string, typedEmail = '') => {
  type messages =
    | 'Not Valid'
    | 'something is wrong with onboarding please try again later'
    | 'email not found in local storage'
    | 'The email provided does not match the sign-in email address.'
    | 'somthing is wrong'
    | 'Invalid Email'
    | 'ok';

  const response: Res<undefined, messages> = {
    success: false,
    message: 'Not Valid',
    data: undefined,
  };

  if (firebase.auth().isSignInWithEmailLink(url)) {
    let emailInLocalStorage = window.localStorage.getItem('emailForSignIn');
    let email: string = '';

    if (!emailInLocalStorage) {
      if (typedEmail !== '') {
        email = typedEmail.trim();
      } else {
        response.message = 'email not found in local storage';
        return response;
      }
    } else {
      email = emailInLocalStorage;
    }

    await firebase
      .auth()
      .signInWithEmailLink(email, url)
      .then(async (result) => {
        if (result) {
          window.localStorage.removeItem('emailForSignIn');
          console.log('resulttttt', result);
          const isNewUser = result.additionalUserInfo?.isNewUser;

          if (isNewUser) {
            const query = queryString.parse(url);
            const docId = query[tempDocIdKey];

            const onboardingResult = await onboardNewUser(docId);
            // onboardingResult.success = response.success;
            console.log('onboardingResult', onboardingResult);

            if (onboardingResult.success) {
              response.success = true;
              response.message = 'ok';
            } else {
              response.message =
                'something is wrong with onboarding please try again later';
            }
          } else {
            response.success = true;
            response.message = 'ok';
          }
        } else {
          response.success = false;
          response.message = 'Not Valid';
        }
      })
      .catch((error) => {
        console.error('err', error);

        if (error.code === 'auth/invalid-email') {
          response.message = 'Invalid Email';
        } else {
          response.message = 'somthing is wrong';
        }

        response.success = false;
      });
  }

  return response;
};
export const logUserOut = async () => {
  return await firebase.auth().signOut();
};

// reset
export const changeUserPassword = async (newPassword: string) => {
  const user = getCurrentUser();
  type msg = 'error' | 'ok' | 'invalid password';
  const response: Res<undefined, msg> = {
    data: undefined,
    message: 'error',
    success: false,
  };
  if (user) {
    user
      .updatePassword(newPassword)
      .then((x) => {
        response.success = true;
        response.message = 'ok';
        return response;
      })
      .catch((err) => {
        console.log('err', err);
        response.success = false;
        response.message = 'invalid password';
        return response;
      });
  }
  return response;
};
export const sendResetPasswordEmail = async (email: string) => {
  const timeedEmail = email.trim();
  type msg =
    | 'email_is_sent'
    | 'email_does_not_exist'
    | 'invalid_email'
    | 'error';
  const response: Res<undefined, msg> = {
    data: undefined,
    message: 'error',
    success: false,
  };
  return firebase
    .auth()
    .sendPasswordResetEmail(timeedEmail)
    .then((x) => {
      console.log('reset password success', x);
      response.success = true;
      response.message = 'email_is_sent';
      return response;
    })
    .catch((err) => {
      console.log('reset passwrod err', err);
      response.success = false;
      const errCode = err.code;
      if (errCode === 'auth/user-not-found') {
        response.message = 'email_does_not_exist';
      }
      if (errCode === 'auth/invalid-email') {
        response.message = 'invalid_email';
      }
      return response;
    });
};
export { saveUserInformation, onboardNewUser };
