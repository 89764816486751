import { Item } from 'interfaces';
import _ from 'lodash';
import { DropResult } from 'react-beautiful-dnd';

export const reorderBasedOnDraggableResult = <T>(
  drop: DropResult,
  list: T[]
) => {
  const result = Array.from(list);
  if (drop.destination) {
    const newIndex = drop.destination.index;
    const oldIndex = drop.source.index;
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);
  }
  return result;
};

export const sortItems = (items: Item[]): Item[] => {
  const sorted = _.orderBy(items, ['categoryIndex', 'itemIndex'], 'asc');
  return sorted;
};
