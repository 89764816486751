import { Item, MenuDialogValues, Store, StoreLinks } from 'interfaces';
import { editDefaultStore } from 'services/store';
import { createTimestamp } from 'utils/dates';
import _ from 'lodash';
import produce from 'immer';
import { Logo } from 'interfaces/common';

type Values = MenuDialogValues & {
  socialLinks: StoreLinks;
  primaryColor: string;
};

export const editStoreController = async (
  values: Values,
  image: Logo,
  storeInformationBeforeUpdate: Store,
  docId: string
) => {
  const linksWithOutEmptyOnes = _.pickBy(values.socialLinks, (value) => {
    const socialHasLink = !_.isEmpty(value);
    return socialHasLink === true;
  });
  const theme = produce(storeInformationBeforeUpdate.theme, (draft) => {
    if (draft.palette.light?.primary) {
      draft.palette.light.primary.main = values.primaryColor;
    }
    if (draft.palette.dark?.primary) {
      draft.palette.dark.primary.main = values.primaryColor;
    }
  });
  const store: Store = {
    ...storeInformationBeforeUpdate,
    description: {
      default: values.descriptionEnglish || '',
      defaultLanguage: {
        language: 'english',
        languageCode: 'en',
      },
      translations: [
        {
          languageCode: 'ar',
          language: 'arabic',
          translation: values.descriptionArabic,
        },
      ],
    },
    logo: image,
    name: {
      default: values.nameEnglish,
      defaultLanguage: {
        language: 'english',
        languageCode: 'en',
      },
      translations: [
        {
          languageCode: 'ar',
          language: 'arabic',
          translation: values.nameArabic,
        },
      ],
    },
    socialLinks: linksWithOutEmptyOnes,
    theme: theme,
  };
  console.log('storeeee', store);
  return editDefaultStore(store);
};
