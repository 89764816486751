import { StoreContext } from 'context/store';
import { useContext } from 'react';

const useWebsite = () => {
  const { store } = useContext(StoreContext);
  const storeURL = () => {
    const { REACT_APP_STORE_WEBSITE_DOMAIN } = process.env;
    const url = `${REACT_APP_STORE_WEBSITE_DOMAIN}/home?q=${store.storeId}`;
    return url;
  };
  return {
    storeURL: storeURL(),
  };
};

export default useWebsite;
