import joi from 'joi';
import {
  nameSchema,
  uniqueIdSchema,
  logoSchema,
  ratingSchema,
  descriptionSchema,
  multiSelectedCategory,
  multiStoreTypeSchema,
} from '../index';

import { selctedStore } from 'validations/default-values';
import { themeSchema } from './theme';
export const singleSelectedStoreSchema = joi
  .object({
    storeId: uniqueIdSchema,
    name: nameSchema,
    logo: logoSchema,
    categories: multiSelectedCategory,
    type: multiStoreTypeSchema,
    rating: ratingSchema,
    description: descriptionSchema,
    theme: themeSchema,
    socialLinks: joi.object({}).pattern(joi.string(), joi.string()).default({}),
  })
  .default(selctedStore)
  .options({ stripUnknown: true });

export default singleSelectedStoreSchema;
