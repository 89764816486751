import joi from 'joi';
import {
  nameSchema,
  uniqueIdSchema,
  multiImageSchema,
  keywordsSchema,
  descriptionSchema,
} from '../../common';

export const categorySchema = joi
  .object({
    name: nameSchema,
    id: uniqueIdSchema,
    description: descriptionSchema,
    keywords: keywordsSchema,
    images: multiImageSchema,
    depth: joi.number().required(),
    path: joi.string().required(),
    storeId: uniqueIdSchema,
    numberOfItems: joi.number().min(0).default(0),
  })
  .options({ stripUnknown: true, abortEarly: false });

export default categorySchema;
