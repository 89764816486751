import { ObjectID } from 'bson';

export const objectId = (id: any = false) => {
  return id !== false ? new ObjectID(id) : new ObjectID();
};

export const objectIdString = (id: any = false) => {
  return objectId(id).toString();
};
export default objectIdString;
