import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    withOutBackgroudColor: {
      backgroundColor: 'transparent',
    },
    hide: {
      visibility: 'hidden',
    },
    inlineLoading: {
      alignItems: 'center',
      display: 'flex',
    },
    inlineText: {
      margin: 15,
    },
  })
);
interface Props {
  active: boolean;
  loadingType: 'fullscreen' | 'inline';
  text: string;
  progress?: number;
  size: number;
  withBackgroudColor: boolean;
  className?: string;
}

const LoadingScreen = (props: Props) => {
  const classes = useStyles();

  if (props.active === false) return null;
  const WithProgress = () => {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          size={55}
          //variant="determinate"
          color="primary"
          //value={props.progress}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="inherit"
          >{`${Math.round(props.progress || 0)}%`}</Typography>
        </Box>
      </Box>
    );
  };
  const Text = () => {
    return (
      <>
        <Typography variant="h5">{props.text}</Typography>
      </>
    );
  };

  const InlineLoading = () => {
    return (
      <div
        className={clsx(classes.inlineLoading, props.className, {
          [classes.inlineLoading]: props.active,
        })}
      >
        <CircularProgress color="primary" size={20} />

        <div>
          <Typography
            className={clsx(classes.inlineText, {
              [classes.hide]: isEmpty(props.text),
            })}
            variant="caption"
            color="textSecondary"
          >
            {props.text}
          </Typography>
        </div>
      </div>
    );
  };

  if (props.loadingType === 'inline') return <InlineLoading />;
  return (
    <Backdrop
      className={clsx({
        [classes.backdrop]: props.withBackgroudColor,
        [classes.withOutBackgroudColor]: !props.withBackgroudColor,
      })}
      open={props.active}
    >
      <Grid
        container
        spacing={5}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          {props.progress === undefined ? (
            <CircularProgress color="primary" size={props.size} />
          ) : (
            <WithProgress />
          )}
        </Grid>
        <Grid item>
          <Text />
        </Grid>
      </Grid>
    </Backdrop>
  );
};
const defaultProps: Props = {
  active: false,
  loadingType: 'fullscreen',
  text: '',
  size: 50,
  withBackgroudColor: true,
};
LoadingScreen.defaultProps = defaultProps;

export default LoadingScreen;
