import joi from 'joi';
import {
  nameSchema,
  timestampSchema,
  phoneSchema,
  uniqueIdSchema,
  subscriptionSchema,
  ratingSchema,
  multiSelectedCategory,
  descriptionSchema,
  specificStringSchema,
  themeSchema,
  currencySchema,
} from '../../common';
import { store } from 'validations/default-values';
import { StoreType } from 'interfaces/common';

// interface - store
const storeTypeSchema = specificStringSchema<StoreType>(
  'coffee',
  'restaurant'
).default('restaurant');
const storeTypeArraySchema = joi
  .array()
  .items(storeTypeSchema)
  .default(['restaurant']);
const logoSchema = joi
  .object({
    url: joi.string().allow('').default(''),
    public_id: joi.string().allow('').default(''),
  })
  .options({ stripUnknown: true })
  .default({
    public_id: '',
    url: '',
  });
const storeSchema = joi
  .object({
    name: nameSchema,
    storeId: uniqueIdSchema,
    type: storeTypeArraySchema,
    logo: logoSchema,
    categories: multiSelectedCategory,
    lastSignedIn: timestampSchema,
    createdAt: timestampSchema,
    employees: joi.number().min(1).default(1),
    phone: phoneSchema.allow(''),
    branches: joi.number().min(0).default(0),
    subscription: subscriptionSchema,
    isActive: joi.boolean().default(true),
    rating: ratingSchema,
    description: descriptionSchema,
    theme: themeSchema,
    socialLinks: joi.object({}).pattern(joi.string(), joi.string()).default({}),
    currency: currencySchema,
    websiteConfig: joi.object({}).default({}),
  })
  .default(store)
  .options({ stripUnknown: true });

export const sotreInfoForItemSchema = joi
  .object({
    name: nameSchema,
    storeId: uniqueIdSchema,
    logo: logoSchema,
    type: storeTypeArraySchema,
    categories: multiSelectedCategory,
  })
  .options({ stripUnknown: true });
export default storeSchema;
