import joi from 'joi';
import { TypographyOption } from 'interfaces/common';
import typography from 'theme/typography';
import { defaultTheme } from 'validations/default-values';
const typographyOptionSchema = (typographyOption: TypographyOption) => {
  const {
    color,
    fontWeight,
    fontSize,
    letterSpacing,
    lineHeight,
    textTransform,
  } = typographyOption;
  return joi
    .object({
      color: joi.string().default(color),
      fontSize: joi.number().default(fontSize),
      fontWeight: joi.string().default(fontWeight),
      letterSpacing: joi.string().default(letterSpacing),
      lineHeight: joi.string().default(lineHeight),
      textTransform: joi.string().default(textTransform),
    })
    .default({})
    .options({ stripUnknown: true });
};

// export const typegraphySchema = joi
//   .object({
//     h1: typographyOptionSchema(typography.h1),
//     h2: typographyOptionSchema(typography.h2),
//     h3: typographyOptionSchema(typography.h3),
//     h4: typographyOptionSchema(typography.h4),
//     h5: typographyOptionSchema(typography.h5),
//     h6: typographyOptionSchema(typography.h6),
//     subtitle1: typographyOptionSchema(typography.subtitle1),
//     subtitle2: typographyOptionSchema(typography.subtitle2),
//     body1: typographyOptionSchema(typography.body1),
//     body2: typographyOptionSchema(typography.body2),
//     button: typographyOptionSchema(typography.button),
//     caption: typographyOptionSchema(typography.caption),
//     overline: typographyOptionSchema(typography.overline),
//     fontFamily: joi.string(),
//   })
//   .default(typography)
//   .options({ stripUnknown: true });

export const colorOptionsSchema = joi
  .object({
    contrastText: joi.string().default('').allow(''),
    dark: joi.string().default('').allow(''),
    main: joi.string().default('').allow(''),
    light: joi.string().default('').allow(''),
  })
  .default({})
  .options({ stripUnknown: true });

export const paletteSchema = joi
  .object({
    type: joi.string().valid('light', 'dark'),
    primary: colorOptionsSchema,
    secondary: colorOptionsSchema,
    success: colorOptionsSchema,
    info: colorOptionsSchema,
    warning: colorOptionsSchema,
    error: colorOptionsSchema,
    background: colorOptionsSchema,
    text: joi
      .object({
        primary: joi.string().default('').allow(''),
        secondary: joi.string().default('').allow(''),
        link: joi.string().default('').allow(''),
      })
      .default({})
      .options({ stripUnknown: true }),
  })
  .default({})
  .options({ stripUnknown: true });

export const themeSchema = joi
  .object({
    palette: {
      light: paletteSchema,
      dark: paletteSchema,
    },
    typography: joi.object().default({}),
    direction: joi.object().default({}),
    overrides: joi.object().default({}),
    transitions: joi.object().default({}),
    zIndex: joi.object().default({}),
  })
  .default(defaultTheme)
  .options({ stripUnknown: true });
export default themeSchema;
