import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import { Language } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'black',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative',
  },
  toolbar: {
    padding: '0 10rem',
  },
  back: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    '& svg': {
      width: 14,
    },
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 'x-large',
  },
}));

interface Props {
  children?: any;
}

const NavMinimal = (props: Props): ReactElement => {
  const classes = useStyles();
  const { children } = props;

  return (
    <React.Fragment>
      {/* <AppBar position="fixed" className={classes.root}></AppBar> */}
      <Language topRight={true} />
      <div>{children}</div>
    </React.Fragment>
  );
};

const defaultProps: Props = {};

NavMinimal.defaultProps = defaultProps;

export default NavMinimal;
