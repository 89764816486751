import app from 'firebase-app';
type Event =
  | 'item_add'
  | 'item_update'
  | 'item_delete'
  | 'category_add'
  | 'category_update'
  | 'category_delete'
  | 'store_info_update';
export const trackEvent = (event: Event) => {
  return app.analytics().logEvent(event);
};
