import * as React from 'react';
import DraggableListItem from './drag-item';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { Grid } from '@material-ui/core';
export type DraggableListProps = {
  children: any;
  droppableId: string;
  direction: 'horizontal' | 'vertical';
  onDragEnd: OnDragEndResponder;
};

const DraggableList = React.memo(
  ({ children, onDragEnd, droppableId, direction }: DraggableListProps) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable direction={direction} droppableId={droppableId}>
          {(provided) => (
            <Grid
              container
              spacing={3}
              style={{ justifyContent: 'center' }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {/* {items.map((item, index) => (
                  <DraggableListItem item={item} index={index} key={item.id} />
                ))} */}
              {children}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default DraggableList;
