import React, { ReactElement, Suspense } from 'react';
import { IconButtonProps } from 'components/props';
import IconButton from '@material-ui/core/IconButton';
import Icon from './find-icon';

const CustomIcon = (props: IconButtonProps): ReactElement => {
  const { icon, className, color, buttonSize, iconSize, onClick } = props;

  return (
    <Suspense fallback={null}>
      {onClick ? (
        <IconButton onClick={onClick} size={buttonSize}>
          <Icon
            icon={icon}
            fontSize={iconSize}
            className={className}
            color={color}
          />
        </IconButton>
      ) : (
        <Icon
          icon={icon}
          fontSize={iconSize}
          className={className}
          color={color}
        />
      )}
    </Suspense>
  );
};

const defaultProps: IconButtonProps = {
  icon: 'Add',
  color: 'inherit',
  buttonSize: 'medium',
  iconSize: 'medium',
  onClick: undefined,
};

CustomIcon.defaultProps = defaultProps;

export default CustomIcon;
