import { storeDB, userDB } from 'config/db';
import { Item, MultiCategory, MenuDialogValues, Store } from 'interfaces';
import { generateDocumentId } from 'services/common';
import { addMenuItem, editMenuItem } from 'services/menu';
import { createTimestamp } from 'utils/dates';
import _ from 'lodash';

export const addMenuController = async (
  values: MenuDialogValues,
  fireBaseUrl: string,
  public_id: string,
  store: Store,
  categories: MultiCategory = [],
  indexes: { category: number; item: number }
) => {
  const menuItem: Item = {
    id: generateDocumentId(userDB),
    price: values.price,
    currency: store.currency,
    categoryIndex: indexes.category,
    itemIndex: indexes.item,
    name: {
      default: values.nameEnglish,
      defaultLanguage: {
        language: 'english',
        languageCode: 'en',
      },
      translations: [
        {
          language: 'arabic',
          languageCode: 'ar',
          translation: values.nameArabic,
        },
      ],
    },
    description: {
      default: values.descriptionEnglish,
      defaultLanguage: {
        language: 'english',
        languageCode: 'en',
      },
      translations: [
        {
          language: 'arabic',
          languageCode: 'ar',
          translation: values.descriptionArabic,
        },
      ],
    },
    images: fireBaseUrl
      ? [
          {
            url: fireBaseUrl,
            public_id,
            alt: `${values.nameEnglish} - ${values.nameArabic}`,
            index: 0,
            isCoverImage: true,
            keywords: [],
          },
        ]
      : [],
    isLive: values.isLive,
    categories: categories,
    createdAt: createTimestamp(),
    identifiers: [],
    keywords: [],
    modifiedAt: createTimestamp(),
    rating: 0,
    store: {
      categories: store.categories,
      logo: store.logo,
      name: store.name,
      storeId: store.storeId,
      type: store.type,
    },
    addons: [],
    information: {
      calories: values.calories || 0,
    },
    variants: [],
    websiteConfig: {},
  };
  return addMenuItem(menuItem);
};

export const editMenuController = async (
  values: MenuDialogValues,
  fireBaseUrl: string,
  public_id: string,
  menuID: string,
  store: Store,
  menuItemBefore: Item,
  categories: MultiCategory = [],
  indexes: { category: number; item: number }
) => {
  let itemIndex = menuItemBefore.itemIndex;
  let categoryIndex = menuItemBefore.categoryIndex;
  const categoriesChanged = !_.isEqual(categories, menuItemBefore.categories);
  if (categoriesChanged) {
    itemIndex = indexes.item;
    categoryIndex = indexes.category;
  }
  const menuItem: Item = {
    ...menuItemBefore,
    id: menuID,
    price: values.price,
    currency: store.currency,
    itemIndex: itemIndex,
    categoryIndex: categoryIndex,
    name: {
      default: values.nameEnglish,
      defaultLanguage: {
        language: 'english',
        languageCode: 'en',
      },
      translations: [
        {
          languageCode: 'ar',
          language: 'arabic',
          translation: values.nameArabic,
        },
      ],
    },
    description: {
      default: values.descriptionEnglish,
      defaultLanguage: {
        language: 'english',
        languageCode: 'en',
      },
      translations: [
        {
          languageCode: 'ar',
          language: 'arabic',
          translation: values.descriptionArabic,
        },
      ],
    },
    images: fireBaseUrl
      ? [
          {
            url: fireBaseUrl,
            alt: values.nameEnglish,
            public_id,
            index: 0,
            isCoverImage: true,
            keywords: [],
          },
        ]
      : [],
    isLive: values.isLive,
    modifiedAt: createTimestamp(),
    information: {
      calories: values.calories || 0,
    },
    store: {
      categories: store.categories,
      logo: store.logo,
      name: store.name,
      storeId: store.storeId,
      type: store.type,
    },
    categories: categories,
  };
  return editMenuItem(menuItem);
};
