//import App from 'App';
import { Component, lazy } from 'react';
import { Minmial, NavMinimal } from 'layout';
import { paths } from '../values';
const Home = lazy(() => import('pages/home'));
const Signup = lazy(() => import('pages/sign-up'));
const SingIn = lazy(() => import('pages/sign-in'));
const Menu = lazy(() => import('pages/menu'));
const Settings = lazy(() => import('pages/settings'));
const StorePage = lazy(() => import('pages/store'));
const ResetPassword = lazy(() => import('pages/password-reset'));
const EmailVerification = lazy(() => import('pages/email-verification'));

export interface Route {
  key: string;
  title: string;
  path: string;
  component: Component | Function;
  layout: any;
  exact: boolean;
  isProtected: boolean;
  lazy?: {};
}

var routes: Array<Route> = [];

const pages = {
  // app: {
  //   title: 'vendor', // this is a key for translation NOTE: Do not change it
  //   path: paths.DOMAIN || '',
  //   component: App,
  //   layout: Minmial,
  //   exact: true,
  //   isProtected: false,
  // },
  home: {
    title: 'home', // this is a key for translation NOTE: Do not change it
    path: paths.home,
    component: Home,
    layout: Minmial,
    exact: true,
    isProtected: false,
  },
  menu: {
    title: 'menu', // this is a key for translation NOTE: Do not change it
    path: paths.menu,
    component: Menu,
    layout: Minmial,
    exact: true,
    isProtected: true,
  },
  store: {
    title: 'store', // this is a key for translation NOTE: Do not change it
    path: paths.store,
    component: StorePage,
    layout: Minmial,
    exact: true,
    isProtected: true,
  },
  contact: {
    title: 'contact', // this is a key for translation NOTE: Do not change it
    path: paths.contact,
    component: Home,
    layout: Minmial,
    exact: true,
    isProtected: true,
  },

  // dashboard: {
  //   title: 'dashboard', // this is a key for translation NOTE: Do not change it
  //   path: paths.dashboard,
  //   component: Dashboard,
  //   layout: Main,
  //   exact: true,
  //   isProtected: true,
  // },

  settings: {
    title: 'settings',
    path: paths.settings,
    component: Settings,
    layout: Minmial,
    exact: true,
    isProtected: true,
  },
  signIn: {
    title: 'SignIn',
    path: paths.signIn,
    component: SingIn,
    layout: Minmial,
    exact: true,
    isProtected: false,
  },
  resetPassword: {
    title: 'Reset Password',
    path: paths.resetPassword,
    component: ResetPassword,
    layout: Minmial,
    exact: true,
    isProtected: false,
  },
  signup: {
    title: 'SignUp',
    path: paths.signup,
    component: Signup,
    layout: NavMinimal,
    exact: true,
    isProtected: false,
  },
  emailVerification: {
    title: '',
    path: paths.emailVerification,
    component: EmailVerification,
    layout: NavMinimal,
    exact: true,
    isProtected: false,
  },
};
for (const [key, value] of Object.entries(pages)) {
  routes.push({ key, ...value });
}

export { pages, routes };
