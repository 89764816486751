import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { LazyLoader, RouteWithLayout } from './components';
import { routes } from './constant/routes';
import { paths } from './constant/values';
import { UserContext, ItemsContext, CategoryContext } from 'context';
import { useItems, useCategories } from 'hooks';

const MyRoutes = () => {
  const isLoggedIn = React.useContext(UserContext).isLoggedIn;
  const {
    getItems,
    updateItem,
    items,
    reorderItem,
    saveAllItems,
    getItemsByCategory,
    deleteItem,
    findItemById,
    updateItemIndexesAfterCategoryChange,
  } = useItems();
  const {
    getCategories,
    updateCategory,
    categories,
    reorderCategory,
    findCategory,
  } = useCategories();

  return (
    <CategoryContext.Provider
      value={{
        categories: categories,
        setCategory: updateCategory,
        fetchCategories: getCategories,
        reorderCategory,
        findCategory: findCategory,
      }}
    >
      <ItemsContext.Provider
        value={{
          items: items,
          setItem: updateItem,
          fetchItems: getItems,
          findItemById,
          reorderItem,
          updateItemIndexesAfterCategoryChange,
          saveAllItems,
          getItemsByCategory,
          deleteItem,
        }}
      >
        <Switch>
          <Redirect exact from="/" to={paths.home} />
          {isLoggedIn && <Redirect exact from={paths.signIn} to={paths.home} />}

          {routes.map((route) => {
            return (
              <RouteWithLayout
                key={route.key}
                component={route.component}
                exact={route.exact}
                layoutTitle={route.title}
                layout={route.layout}
                path={route.path}
                isProtected={route.isProtected}
              />
            );
          })}
          <Redirect to="/not-found" />
        </Switch>
      </ItemsContext.Provider>
    </CategoryContext.Provider>
  );
};

export default MyRoutes;
