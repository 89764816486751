import joi from 'joi';

import {
  nameSchema,
  uniqueIdSchema,
  ratingSchema,
  multiSelectedCategory,
  multiImageSchema,
  timestampSchema,
  identifiersSchema,
  priceSchema,
  keywordsSchema,
  descriptionSchema,
  currencySchema,
} from '../../common';
import { item } from 'validations/default-values';
import { sotreInfoForItemSchema } from '../store';
// item - interface

export const itemStoreSchema = joi
  .object({})
  .options({ stripUnknown: true })
  .required();

export const itemSchema = joi
  .object({
    id: uniqueIdSchema,
    name: nameSchema,
    description: descriptionSchema,
    price: priceSchema,
    rating: ratingSchema,
    store: sotreInfoForItemSchema,
    images: multiImageSchema,
    createdAt: timestampSchema,
    modifiedAt: timestampSchema,
    isLive: joi.boolean().default(true),
    identifiers: identifiersSchema,
    categories: multiSelectedCategory,
    keywords: keywordsSchema,
    currency: currencySchema,
    itemIndex: joi.number().default(9999),
    categoryIndex: joi.number().default(9999),

    information: joi
      .object({
        calories: joi.number().default(0),
      })
      .options({ stripUnknown: true })
      .default({}),
    variants: joi.array().default([]),
    addons: joi.array().default([]),
    websiteConfig: joi.object({}).default({}),
  })
  .default(item)
  .options({ stripUnknown: true, abortEarly: false });

export const multiItemSchema = joi.array().items(itemSchema).default([]);

export default itemSchema;
