import { CardWithImage } from 'components';
import { Item } from 'interfaces';
import { itemImageHeight, itemImgaeWidth } from 'constant/values/common';
import { DraggableItem } from 'components';
interface ItemCardProps {
  menu: Item;
}
const ItemCard = (props: ItemCardProps) => {
  const { menu } = props;

  return (
    <CardWithImage
      menu={menu}
      image={menu.images[0]}
      height={itemImageHeight}
      width={itemImgaeWidth}
    />
  );
};

export default ItemCard;
