import React from 'react';
import ReactDOM from 'react-dom';
import './styles/root.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserContext } from 'context';
import { isUserSignedIn } from 'services/user';
import firebase from 'firebase-app';
import '@fontsource/dancing-script';
import '@fontsource/kirang-haerang';

firebase.auth().onAuthStateChanged((user) => {
  const isLoggedIn = user ? true : false;
  const displayName = user?.displayName || '';
  const email = user?.email || '';
  return ReactDOM.render(
    <UserContext.Provider value={{ isLoggedIn, displayName, email }}>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </UserContext.Provider>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
