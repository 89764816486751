import { Category, Item, Res } from 'interfaces';
import { UserLocation } from 'interfaces/common';
import { createContext } from 'react';
import { UserLocationDefaultInformation } from 'validations/default-values/common';
import { DropResult } from 'react-beautiful-dnd';
export const UserContext = createContext({
  isLoggedIn: false,
  email: '',
  displayName: '',
});

export const UserLocationContext = createContext<UserLocation>(
  UserLocationDefaultInformation
);
type SuccessObject = {
  success: boolean;
  [key: string]: any;
};

export const CategoryContext = createContext<{
  categories: Category[];
  setCategory: (category: Category) => void;
  reorderCategory: (
    categoryIds: {
      id: string;
      [key: string]: any;
    }[],
    allCategoires?: Category[]
  ) => Promise<SuccessObject>;
  fetchCategories: () => Promise<Category[]>;
  findCategory: (
    categoryId: string,
    allCategoires?: Category[]
  ) => {
    index: number;
    category: Category | undefined;
  };
}>({
  categories: [],
  setCategory: () => undefined,
  reorderCategory: async () => ({ success: false }),
  fetchCategories: async () => [],
  findCategory: () => ({ index: -1, category: undefined }),
});

export const ItemsContext = createContext<{
  items: Item[];
  setItem: (item: Item) => void;
  deleteItem: (item: Item) => Promise<Res>;
  updateItemIndexesAfterCategoryChange: (
    targetItem: Item
  ) => Promise<SuccessObject>;
  findItemById: (
    itemId: string,
    allItems?: Item[]
  ) => { index: number; item: Item | undefined };
  reorderItem: (
    itemIds: {
      id: string;
      [key: string]: any;
    }[],
    allItems?: Item[]
  ) => Promise<SuccessObject>;
  fetchItems: (syncToWebsite?: boolean) => Promise<void>;
  saveAllItems: () => Promise<void>;
  getItemsByCategory: (categoryId: string, itemsToFilter?: Item[]) => Item[];
}>({
  items: [],
  updateItemIndexesAfterCategoryChange: async () => ({ success: false }),
  setItem: () => undefined,
  findItemById: () => ({ index: -1, item: undefined }),
  deleteItem: async () => ({
    success: false,
    message: 'error',
    data: undefined,
  }),
  reorderItem: async () => ({ success: false }),
  fetchItems: async () => undefined,
  saveAllItems: async () => undefined,
  getItemsByCategory: () => [],
});
