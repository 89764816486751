import { createTimestamp } from 'utils/dates';
import { Store } from 'interfaces';
import { name, description } from 'validations/default-values';
import { getDefaultSubscription } from 'validations/default-values/common';
import { defaultTheme } from '../store-selected';
const store = (): Store => {
  return {
    name: name,
    storeId: '',
    type: ['restaurant'],
    logo: {
      url: '',
      public_id: '',
    },
    categories: [],
    lastSignedIn: createTimestamp(),
    createdAt: createTimestamp(),
    employees: 1,
    phone: '',
    branches: 1,
    subscription: getDefaultSubscription(),
    isActive: true,
    rating: 0,
    description: description,
    theme: defaultTheme,
    socialLinks: {},
    currency: 'SAR',
    websiteConfig: {},
  };
};

export default store;
