import { NameDB } from 'interfaces';

export const userDB: NameDB = {
  db: '',
  collection: 'users',
};

export const userTemporarilyDB: NameDB = {
  db: '',
  collection: 'users-temporarily',
};

export const menuDB: NameDB = {
  db: '',
  collection: 'menu-items',
};

export const categoryDB: NameDB = {
  db: '',
  collection: 'categories',
};

export const storeDB: NameDB = {
  db: '',
  collection: 'stores',
};

export const storesWebsiteDB: NameDB = {
  db: '',
  collection: 'store-website',
};
