import { db, generateDocumentId } from 'services/common';
import { storeDB, userDB } from 'config/db';
import { Store, User, Res, StoreIntialInformation } from 'interfaces';
import firebase from 'firebase';
import { storeSchemaValidation } from 'validations';
import {
  name as nameDefault,
  store as defaultStore,
} from 'validations/default-values';
import { createTimestamp } from 'utils/dates';
import produce from 'immer';

export const editDefaultStore = async (store: Store) => {
  type msg = 'ok' | 'validation error' | 'error';
  const response: Res<Store | undefined, msg> = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    const validation = storeSchemaValidation(store);
    if (validation.isValid) {
      const validatedStore = validation.data;
      await db(storeDB).doc(store.storeId).set(validatedStore);
      response.data = validatedStore;
      response.message = 'ok';
      response.success = true;
    } else {
      response.message = 'validation error';
      response.success = false;
    }
  } catch (error) {
    console.log('err', error);
    response.success = false;
    response.message = 'error';
  }
  return response;
};

export const getCurrentStore = async () => {
  try {
    const user = firebase.auth().currentUser;
    const userDetails = await db(userDB)
      .where('email', '==', user?.email)
      .get();
    const storesAccess = userDetails.docs[0].data().storesAccess[0];
    const { storeId } = storesAccess;
    const storeResponse = await db(storeDB).doc(storeId).get();
    // @ts-ignore
    const store: Store = storeResponse.data();
    if (!store) throw 'No Store found';
    return { success: true, store: store, docId: store.storeId };
  } catch (error) {
    return { success: false, error: error };
  }
};

export const createNewStore = async (
  user: User,
  storeIntialInfo?: StoreIntialInformation
) => {
  type msg =
    | 'ok'
    | 'error'
    | 'store information is not valid'
    | 'error while saving store information';

  const response: Res<undefined, msg> = {
    data: undefined,
    message: 'ok',
    success: false,
  };
  try {
    const newStore: Store = produce(defaultStore(), (draft) => {
      const userRegisterStore = user.storesAccess[0];
      draft.createdAt = createTimestamp();
      draft.name = userRegisterStore.name;
      draft.storeId = userRegisterStore.storeId;
      if (storeIntialInfo) {
        draft.currency = storeIntialInfo.currency;
        draft.type = storeIntialInfo.type;
      }
    });
    const validation = storeSchemaValidation(newStore);
    if (validation.isValid) {
      const validatedStoreInfo = validation.data;
      const saveStoreInfoResult = await editDefaultStore(validatedStoreInfo);
      response.success = saveStoreInfoResult.success;
      if (saveStoreInfoResult.success === false) {
        response.message = 'error while saving store information';
      }
    } else {
      response.success = false;
      response.message = 'store information is not valid';
    }
  } catch (err) {
    console.log('err', err);
    response.success = false;
    response.message = 'error';
  }
  return response;
};
