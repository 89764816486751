import { useEffect, useContext, useState } from 'react';
import { Category } from 'interfaces';
import { StoreContext } from 'context/store';
import { fetchCategories } from 'services/category';
import { fetchStoreItmes } from 'services/menu';
import { functions } from 'services/common';
import _ from 'lodash';
import { sortItems } from 'utils';
import { ItemsContext } from 'context';
const useCategories = () => {
  const { store } = useContext(StoreContext);
  const [categories, setCategories] = useState<Category[]>([]);
  const storeId = store.storeId;

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const latestCategories = await fetchCategories(storeId);
    setCategories(latestCategories);
    return latestCategories;
  };
  const updateCategory = (category: Category) => {};

  const reorderCategory = async (
    categoryIds: { id: string; [key: string]: any }[],
    allCategoires = categories
  ) => {
    const allItems = await fetchStoreItmes(store.storeId);
    let updatedCategories: Category[] = [];
    categoryIds.map((categoryInfo, categoryIndex) => {
      const categoryId = categoryInfo.id;
      const category = findCategory(categoryId, allCategoires).category;
      if (category) {
        category.path = categoryIndex.toString();

        allItems.map((item, itemIndex) => {
          const hasCateogry = !_.isEmpty(item.categories);
          if (hasCateogry) {
            const currentCategoryId = item.categories[0].id;
            if (currentCategoryId === categoryId) {
              allItems[itemIndex].categoryIndex = categoryIndex;
            }
          }
        });
        updatedCategories.push(category);
        return category;
      }
    });
    const sorted = sortItems(allItems);
    setCategories(updatedCategories);
    return await functions({
      function: 'bulkUpdate',
      data: {
        categories: updatedCategories,
        items: sorted,
        storeId: store.storeId,
      },
    });
  };
  const findCategory = (categoryId: string, allCategoires = categories) => {
    let index = -1;

    const category = allCategoires.find((currenctCategory, i) => {
      if (currenctCategory.id === categoryId) {
        index = i;
        return currenctCategory;
      }
    });
    return { index, category };
  };
  return {
    getCategories,
    updateCategory,
    categories,
    findCategory,
    reorderCategory,
  };
};

export default useCategories;
