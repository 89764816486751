import { User, Res } from 'interfaces';
import { db, functions, getCurrentUser } from 'services/common';
import { userDB } from 'config/db';
import isEmpty from 'lodash/isEmpty';
import { generateDocumentId } from 'services/common';
import produce from 'immer';
import firebase from 'firebase-app';
import axios from 'axios';
interface UserProfile {
  phone?: string;
  email?: string;
  displayName?: string;
}
export const saveUserInformation = async (user: User) => {
  const response: Res<any, string> = {
    success: false,
    data: undefined,
    message: '',
  };
  try {
    const docId = isEmpty(user.docId) ? generateDocumentId(userDB) : user.docId;

    const userCopy = produce(user, (draft: User) => {
      draft.docId = docId;
    });

    await db(userDB).doc(docId).set(userCopy);
    response.success = true;
  } catch (err) {
    console.error(err);
    response.success = false;
  }
  return response;
};
export const updateUserProfile = async (infoToUpdate: UserProfile) => {
  const { displayName } = infoToUpdate;
  await firebase.auth().currentUser?.updateProfile({ displayName });
};
export const updateUserPhoneNumber = async (
  phone?: string,
  userEmail?: string
) => {
  let success = false;
  try {
    const user = getCurrentUser();
    const email = userEmail ? userEmail : user?.email;
    if (email) {
      const result = await functions({
        data: {
          email,
          phone,
        },
        function: 'updateUserPhone',
      });
      console.log('phone update result', result);
      success = result.success;
    }
  } catch (error) {
    console.error('error while update user phone number', error);
  }
  return success;
};
export const removeUserPhone = async (email?: string) => {
  return updateUserPhoneNumber(undefined, email);
};
export const onboardNewUser = async (temporarilyDocId: string) => {
  const response = await functions<Res>({
    function: 'onboardNewUser',
    data: {
      docId: temporarilyDocId,
    },
  });

  return response;
};

export const isEmailAvailable = async (email: string) => {
  const { REACT_APP_API_USER } = process.env;
  const url = `${REACT_APP_API_USER}/email-check`;
  const result = await axios.post(url, { email });
  const response: { isEmailAvailable: boolean } = result.data;
  return response.isEmailAvailable;
};
