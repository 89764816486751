import React, { useEffect, useState } from 'react';
//import logo from './logo.svg';
import { createBrowserHistory } from 'history';
import { Router, useHistory } from 'react-router-dom';
import MyRoutes from './routes';
// import {
//   ThemeProvider,
//   jssPreset,
//   StylesProvider,
// } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { Button, ThemeProvider, Typography, Link } from '@material-ui/core';
import theme from 'theme';
import { StoreContext } from 'context/store';
import { VisitWebsite, LoadingScreen } from 'components';
import i18nNext from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import translations from 'constant/translations';
import { UserContext, UserLocationContext } from 'context';
import { Store } from 'interfaces';
import { store as defaultStore } from 'validations/default-values';
import { getCurrentStore } from 'services/store';
import { UserLocation } from 'interfaces/common';
import { UserLocationDefaultInformation } from 'validations/default-values/common';
import { getUserLocationInformation } from 'services/common';
import _ from 'lodash';
import paths from 'constant/paths';
const browserHistory = createBrowserHistory();

const App = () => {
  const [fetchingStore, setFetchingStore] = useState(false);
  const [store, setStore] = React.useState<{
    store: Store;
    docId: string;
  }>({
    store: defaultStore(),
    docId: '',
  });
  const [userLocation, setUserLocation] = useState<UserLocation>(
    UserLocationDefaultInformation
  );
  i18nNext.use(initReactI18next).init(translations);

  const isLoggedIn = React.useContext(UserContext).isLoggedIn;
  const userIsNotInSignUpPage = window.location.pathname !== paths.signup;
  console.log('userIsNotInSignUpPage', userIsNotInSignUpPage);
  console.log('env', process.env.REACT_APP_CURRENT);
  useEffect(() => {
    if (isLoggedIn) {
      const getStore = async () => {
        setFetchingStore(true);
        const currentStore = await getCurrentStore();
        if (currentStore.store && currentStore.docId) {
          setStore({ store: currentStore.store, docId: currentStore.docId });
        }
        setFetchingStore(false);
      };
      getStore();
    }
  }, []);

  useEffect(() => {
    const savedLng = localStorage.getItem('lang');
    if (savedLng) {
      console.log('we have langugae');
      i18nNext.changeLanguage(savedLng);
    }
  }, []);

  useEffect(() => {
    const getIP = async () => {
      const result = await getUserLocationInformation();
      console.log('getUserLocationInformation', result);
      // if (result.success && result.data) {
      //   setUserLocation(result.data);
      // } else {
      //   console.error('error with geoip');
      // }
    };
    getIP();
  }, []);
  const handleStoreUpdate = (updatedStore: Store) => {
    setStore({ store: updatedStore, docId: store.docId });
    return undefined;
  };
  return (
    <UserLocationContext.Provider value={userLocation}>
      <StoreContext.Provider
        value={{
          store: store.store,
          docId: store.docId,
          setStore: handleStoreUpdate,
        }}
      >
        <Router history={browserHistory}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
              {isLoggedIn && userIsNotInSignUpPage && (
                <div
                  style={{
                    position: 'absolute',
                    right: '1rem',
                    top: '1rem',
                    zIndex: 10000,
                    marginTop: 10,
                    marginRight: 10,
                  }}
                >
                  <VisitWebsite />
                </div>
              )}
              {fetchingStore ? <LoadingScreen active={true} /> : <MyRoutes />}
            </SnackbarProvider>
          </ThemeProvider>
        </Router>
      </StoreContext.Provider>
    </UserLocationContext.Provider>
  );
};

export default App;
