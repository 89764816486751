import React, { ReactElement, useContext } from 'react';
import { Button, Link } from '@material-ui/core';

import { useWebsite } from 'hooks';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'context';

const VisitWebsite = () => {
  const { storeURL } = useWebsite();
  const [t] = useTranslation();
  const isLoggedIn = useContext(UserContext).isLoggedIn;
  if (isLoggedIn === false) return null;
  return (
    <Link color="primary" href={storeURL} target="_blank">
      {t('visitStore')}
    </Link>
  );
};

export default VisitWebsite;
