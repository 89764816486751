import React, { ReactElement } from 'react';
import { iconList, IconSize, IconColors } from 'components/props';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Menu from '@material-ui/icons/Menu';
import Notifications from '@material-ui/icons/Notifications';
import Fastfood from '@material-ui/icons/Fastfood';
import CategoryOutlined from '@material-ui/icons/CategoryOutlined';
import Add from '@material-ui/icons/Add';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Storefront from '@material-ui/icons/Storefront';
import Settings from '@material-ui/icons/Settings';
import Dashboard from '@material-ui/icons/Dashboard';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SortIcon from '@material-ui/icons/Sort';
import SvgIcon from '@material-ui/core/SvgIcon';
interface IconProps {
  fontSize?: IconSize;
  className?: any;
  color?: IconColors;
}
interface Props extends IconProps {
  icon: iconList;
}
const SnapchatIcon = (props: IconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.126 16.475c-.057.077-.103.4-.178.655-.086.295-.356.262-.656.203-.437-.085-.827-.109-1.281-.034-.785.131-1.601 1.292-2.969 1.292-1.472 0-2.238-1.156-3.054-1.292-.832-.138-1.31.084-1.597.084-.221 0-.307-.135-.34-.247-.074-.251-.12-.581-.178-.66-.565-.087-1.84-.309-1.873-.878-.008-.148.096-.279.243-.303 1.872-.308 3.063-2.419 2.869-2.877-.138-.325-.735-.442-.986-.541-.648-.256-.739-.55-.7-.752.053-.28.395-.468.68-.468.275 0 .76.367 1.138.158-.055-.982-.194-2.387.156-3.171.667-1.496 2.129-2.236 3.592-2.236 1.473 0 2.946.75 3.608 2.235.349.783.212 2.181.156 3.172.357.197.799-.167 1.107-.167.302 0 .712.204.719.545.005.267-.233.497-.708.684-.255.101-.848.217-.986.541-.198.468 1.03 2.573 2.869 2.876.146.024.251.154.243.303-.033.569-1.314.791-1.874.878z" />
    </SvgIcon>
  );
};
const Icon = (props: Props): ReactElement => {
  const { icon, ...rest } = props;

  switch (icon) {
    case 'snapchat':
      return <SnapchatIcon {...rest} />;
    case 'sort':
      return <SortIcon {...rest} />;
    case 'whatsapp':
      return <WhatsAppIcon {...rest} />;
    case 'twitter':
      return <TwitterIcon {...rest} />;

    case 'facebook':
      return <FacebookIcon {...rest} />;

    case 'instagram':
      return <InstagramIcon {...rest} />;
    case 'linkedin':
      return <LinkedInIcon {...rest} />;

    case 'Storefront':
      return <Storefront {...rest} />;

    case 'Settings':
      return <Settings {...rest} />;

    case 'Dashboard':
      return <Dashboard {...rest} />;

    case 'CategoryOutlined':
      return <CategoryOutlined {...rest} />;

    case 'Fastfood':
      return <Fastfood {...rest} />;

    case 'Add':
      return <Add {...rest} />;

    case 'Menu':
      return <Menu {...rest} />;

    case 'ChevronLeft':
      return <ChevronLeft {...rest} />;

    case 'Notifications':
      return <Notifications {...rest} />;

    case 'PhotoCamera':
      return <PhotoCamera {...rest} />;

    default:
      return <Dashboard {...rest} />;
  }
};

export default Icon;
