import { useState, useCallback, useEffect } from 'react';
import Cropper, { CropperProps } from 'react-easy-crop';
import {
  Dialog,
  makeStyles,
  Button,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';
import getImg from './crop';
const useStyles = makeStyles((theme) => ({
  cropperContainer: {
    position: 'relative',
    height: 250,
    width: '100%',
  },
  btn: {
    textTransform: 'initial',
  },
}));
interface Props {
  src: any;
}
const ImageCropper = (props: Props) => {
  const classes = useStyles();
  const { src } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [open, setOpen] = useState(false);
  const [cropPixels, setCropPixels] = useState<any>(null);
  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      console.log(croppedArea, croppedAreaPixels);
      console.log('croppedArea', croppedArea);
      //getImg(src, croppedAreaPixels, 0);
    },
    []
  );
  useEffect(() => {
    if (cropPixels) {
      const run = async () => {
        const url = await getImg(src, cropPixels, 0);
      };
    }
  }, [cropPixels]);
  const handleOpenClose = () => setOpen(!open);
  return (
    <>
      <Dialog open={open} fullScreen>
        <DialogContent>
          <div className={classes.cropperContainer}>
            <Cropper
              image={src}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpenClose}>Ok</Button>
          <Button onClick={handleOpenClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Button
        className={classes.btn}
        startIcon={<CropFreeIcon />}
        onClick={handleOpenClose}
      >
        Crop
      </Button>
    </>
  );
};

export default ImageCropper;
