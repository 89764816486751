import React, { ReactElement } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
}));

interface Props {
  children?: any;
  to: string;
  className?: any;
}

const StyledLink = (props: Props): ReactElement => {
  const classes = useStyles();
  const { to, children, className } = props;
  return (
    <Link to={to} className={clsx(classes.link, className)}>
      {children}
    </Link>
  );
};

export default StyledLink;
