import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import MenuDialog from 'components/menu-dialog';
import AddImage from 'components/add-image';
import { ItemImage } from 'interfaces/common';
import { Item } from 'interfaces';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useName } from 'hooks';
import { useTranslation } from 'react-i18next';
import { itemImageHeight } from 'constant/values/common';
import { getCloudinaryImageURL } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'visible',
    borderRadius: theme.spacing(1),
  },
  rounded: {
    height: itemImageHeight,
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  img: {
    height: '100%',
    width: '100%',
  },
  close: {
    position: 'absolute',
    zIndex: 1,
    color: 'white',
    cursor: 'pointer',
    right: '-10px',
    top: '-10px',
    filter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))',
    backgroundColor: '#808080',
    borderRadius: 20,
  },
  cardDescription: {
    border: 0,
    width: '100%',
    '&:focus': { outline: 0 },
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  descrption: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  // descrption: {
  //   whiteSpace: 'nowrap',
  //   textOverflow: 'ellipsis',
  // },
}));

interface CardWithImageProps {
  menu: Item;
  image: ItemImage;
  height: number;
  width: number;
}

const CardWithImage = (props: CardWithImageProps) => {
  const { menu, image, height, width } = props;
  const classes = useStyles();
  const childRef: any = React.useRef();
  const [alert, setAlert] = React.useState(false);
  const [operationSuccess, setOperationSuccess] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');

  const handleClickOpen = (menu: Item) => {
    childRef.current.editMenu(menu);
  };
  const handleAlertClose = () => {
    setAlert(false);
  };
  const [t, i18n] = useTranslation();

  const currency = menu.currency;
  return (
    <div className={classes.root} style={{ width }}>
      <Card dir={i18n.dir()}>
        <Snackbar
          open={alert}
          autoHideDuration={3000}
          onClose={handleAlertClose}
        >
          <Alert
            onClose={handleAlertClose}
            severity={operationSuccess ? 'success' : 'error'}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <MenuDialog
          ref={childRef}
          // menu={menu}
          // image={image.url}
          edit={true}
          title={''}
          setAlert={setAlert}
          setAlertMessage={setAlertMessage}
          setOperationSuccess={setOperationSuccess}
        />
        <CardActionArea onClick={() => handleClickOpen(menu)}>
          {image && image.url ? (
            <CardMedia
              style={{
                filter: menu.isLive ? '' : 'grayscale(1)',
              }}
              image={getCloudinaryImageURL(image.public_id)}
              className={classes.rounded}
            />
          ) : (
            <AddImage borderRadius={0} boxShadow={'unset'} addText />
          )}
          <CardContent>
            <div className={classes.cardContent}>
              <Typography
                gutterBottom
                variant="h5"
                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                component="h2"
              >
                {useName(menu.name).getTranslationValue(i18n.language)}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                style={{
                  width: '6rem',
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
                component="h2"
              >
                {menu.price} {currency}
              </Typography>
            </div>
            <Typography
              className={classes.descrption}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {useName(menu.description).getTranslationValue(i18n.language)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default CardWithImage;
