import React from 'react';
import { LoadingScreen } from 'components';

const FullScreenLoadingWithoutLayout = (props: {
  layoutTitle: string;
  active: boolean;
}) => {
  return (
      <LoadingScreen active={props.active} loadingType="fullscreen" />
  );
};
FullScreenLoadingWithoutLayout.defaultProps = {
  layoutTitle: '',
  active: true,
};

export default FullScreenLoadingWithoutLayout;
