import joi from 'joi';
import { timestampSchema } from '../date';
import { getDefaultSubscription } from 'validations/default-values';
import { Membership } from 'interfaces/common';

const basic: Membership = 'basic';
const business: Membership = 'business';
const premium: Membership = 'premium';

const subscriptionSchema = joi
  .object({
    membership: joi.string().valid(basic, business, premium).default(basic),
    startedAt: timestampSchema,
    expiredAt: timestampSchema,
    isForever: joi.boolean().default(true),
  })
  .default(getDefaultSubscription)
  .options({ stripUnknown: true });

export default subscriptionSchema;
