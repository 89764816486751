import { useEffect, useContext, useState } from 'react';
import { Item, Res } from 'interfaces';
import { StoreContext } from 'context/store';
import { deleteMenuItem, fetchStoreItmes } from 'services/menu';
import _ from 'lodash';
import { functions } from 'services/common';
import { sortItems } from 'utils';

const useItems = () => {
  const { store } = useContext(StoreContext);
  const [items, setItems] = useState<Item[]>([]);
  const storeId = store.storeId;
  useEffect(() => {
    getItems();
  }, []);
  const getItems = async (syncToWebsite = false) => {
    const latestItems = await fetchStoreItmes(storeId);
    if (syncToWebsite) {
      sendUpdate(latestItems);
    }
    setItems(latestItems);
  };

  const updateItem = (item: Item) => {};

  const findItemById = (itemId: string, allItems: Item[] = items) => {
    let index = -1;
    const item = allItems.find((item, i) => {
      if (item.id === itemId) {
        index = i;
        return item;
      }
    });
    return { item, index };
  };
  const reorderItem = async (
    itemIds: {
      id: string;
      [key: string]: any;
    }[],
    allItems: Item[] = items
  ) => {
    const itemsCopy = [...allItems];
    itemIds.map((idInfo, itemIndexInCategory) => {
      const result = findItemById(idInfo.id, itemsCopy);
      const itemIndex = result.index;
      itemsCopy[itemIndex].itemIndex = itemIndexInCategory;
    });

    const sorted = sortItems(itemsCopy);
    setItems(sorted);
    return sendUpdate(sorted);
  };
  const saveAllItems = async () => {
    // const savingItems = items.map(async (item) => {
    //   return await editMenuItem(item);
    // });
    // const result = await Promise.all(savingItems);
    // console.log('result saving items', result);
  };
  const getItemsByCategory = (
    categoryId: string,
    itemsToFilter: Item[] = items
  ) => {
    const categoryItems = itemsToFilter.filter((item) => {
      const hasCategory = !_.isEmpty(item.categories);
      if (hasCategory) {
        if (item.categories[0].id === categoryId) return item;
      }
    });
    return categoryItems;
  };
  const sendUpdate = async (items: Item[]) => {
    const result = await functions({
      function: 'bulkUpdate',
      data: {
        categories: [],
        items: items,
        storeId: store.storeId,
      },
    });
    console.log('bulk update result', result);
    return result;
  };
  const updateItemIndexesAfterCategoryChange = async (targetItem: Item) => {
    const latestItems = await fetchStoreItmes(storeId);
    const cateogryId = targetItem.categories[0]?.id;
    const itemsCateogry = getItemsByCategory(cateogryId, latestItems);
    return reorderItem(itemsCateogry, latestItems);
  };
  const deleteItem = async (targetItem: Item) => {
    const response: Res = {
      data: undefined,
      message: 'error',
      success: false,
    };
    const targetItemId = targetItem.id;
    const itemDelete = await deleteMenuItem(targetItemId);
    if (itemDelete.success) {
      try {
        const latestItems = await fetchStoreItmes(store.storeId);
        const categoryId = targetItem.categories[0]?.id;
        const itemsCateogry = getItemsByCategory(categoryId, latestItems);
        reorderItem(itemsCateogry, latestItems);
        response.success = true;
        response.message = 'ok';
      } catch (error) {
        console.error('err while deleting item', error);
      }
    }
    return response;
  };
  return {
    getItems,
    updateItem,
    saveAllItems,
    items,
    findItemById,
    reorderItem,
    updateItemIndexesAfterCategoryChange,
    getItemsByCategory,
    deleteItem,
  };
};

export default useItems;
