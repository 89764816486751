import { categoryDB } from 'config/db';
import { Category, Store } from 'interfaces';
import { addCategory, deleteCategory, editCategory } from 'services/category';
import { generateDocumentId } from 'services/common';

export const addCategoryController = async (
  formValues: {
    english: string;
    arabic: string;
  },
  store: Store,
  path: string
) => {
  const storeData: Store = store;
  const category: Category = {
    id: generateDocumentId(categoryDB),
    name: {
      default: formValues.english,
      defaultLanguage: {
        language: 'english',
        languageCode: 'en',
      },
      translations: [
        {
          language: 'arabic',
          languageCode: 'ar',
          translation: formValues.arabic,
        },
      ],
    },
    depth: 0,
    description: {
      default: '',
      defaultLanguage: { language: 'english', languageCode: 'en' },
      translations: [],
    },
    images: [],
    keywords: [],
    numberOfItems: 0,
    path: path,
    storeId: store.storeId || storeData.storeId,
  };
  return addCategory(category);
};
export const editCategoryController = async (
  formValues: {
    english: string;
    arabic: string;
  },
  categoryBefore: Category
) => {
  const category: Category = {
    ...categoryBefore,
    name: {
      default: formValues.english,
      defaultLanguage: {
        language: 'english',
        languageCode: 'en',
      },
      translations: [
        {
          language: 'arabic',
          languageCode: 'ar',
          translation: formValues.arabic,
        },
      ],
    },
  };
  return editCategory(category);
};
export const deleteCategoryController = async (id: string) => {
  return deleteCategory(id);
};
