import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'hooks';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
}));

interface Props {
  children?: any;
  value: string;
  text?: string;
  btn: ButtonProps;
}

const CopyToClipboard = (props: Props): ReactElement => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { value, btn, text = t('copy') } = props;
  const { showSnackbar } = useNotification();
  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(value);
      showSnackbar(t('copied'));
    } catch (error) {
      showSnackbar(t('error'), 'error');
    }
  };
  return (
    <Button {...btn} onClick={copyToClipboard}>
      {text}
    </Button>
  );
};

const defaultProps: Props = {
  value: '',
  btn: {},
};

CopyToClipboard.defaultProps = defaultProps;

export default CopyToClipboard;
