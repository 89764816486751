import { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Icon } from 'components';
import { iconList } from 'components/props';
import { InputAdornment } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
}));

interface Props {
  children?: any;
  textFeildProps?: TextFieldProps;
  icon: iconList;
}

const TextFeildWithIcon = (props: Props): ReactElement => {
  const classes = useStyles();
  const { icon, textFeildProps } = props;

  return (
    <TextField
      {...textFeildProps}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon icon={icon} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default TextFeildWithIcon;
