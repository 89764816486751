import { SelectedStore, Theme } from 'interfaces/common';
import { name } from 'validations/default-values';
import typography from 'theme/typography';

export const defaultTheme: Theme = {
  palette: {
    dark: {
      type: 'dark',
      primary: {
        main: '',
      },
      secondary: {
        main: '',
      },
      text: { primary: '', secondary: '#fffff' },
    },
    light: {
      type: 'light',
      secondary: { main: '' },
      primary: { main: '' },
      text: { primary: '', secondary: '#fffff' },
    },
  },
  typography,
  transitions: {},
  direction: {},
  overrides: {},
  zIndex: {},
};
export const selctedStore: SelectedStore = {
  name: name,
  description: name,
  storeId: '',
  logo: {
    public_id: '',
    url: '',
  },
  isActive: true,
  categories: [],
  type: [],
  rating: 0,
  theme: defaultTheme,
  socialLinks: {},
  currency: 'SAR',
  websiteConfig: {},
};

export default selctedStore;
