import joi from 'joi';
//import { nameSchema, uniqueIdSchema, keywordsSchema } from 'validations/common';
import keywordsSchema from 'validations/common/keywords';
import nameSchema from 'validations/common/name';
import uniqueIdSchema from 'validations/common/id';
export const singleSelectedCategorySchema = joi
  .object({
    name: nameSchema,
    id: uniqueIdSchema,
  })
  .default({})
  .options({ stripUnknown: true });

export const multiSelectedCategory = joi
  .array()
  .items(singleSelectedCategorySchema)
  .default([]);
// here is a bug
