import joi from 'joi';
import keywordsSchema from 'validations/common/keywords';

export const singleImageSchema = joi
  .object({
    url: joi.string().default(''),
    alt: joi.string().default('').allow(''),
    index: joi.number().default(0).min(0),
    isCoverImage: joi.boolean().default(false),
    public_id: joi.string().required(),
    keywords: keywordsSchema,
  })
  .default({})
  .options({ stripUnknown: true });

export const multiImageSchema = joi
  .array()
  .items(singleImageSchema)
  .default([]);
