import { Store } from 'interfaces';
import { createContext } from 'react';

import { store } from 'validations/default-values';

export const StoreContext = createContext({
  store: store(),
  docId: '',
  setStore: (store: Store) => undefined,
});
