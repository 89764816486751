import { useSnackbar } from 'notistack';
type varaint = 'success' | 'default' | 'error' | 'warning' | 'info';

const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSnackbar = (
    message: string,
    variant: varaint = 'success',
    autoHideDuration = 1500,
    options = {}
  ) => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      variant: variant,
      autoHideDuration: autoHideDuration,
      ...options,
    });
  };
  const showConditionalSackbar = (
    condition: boolean = true,
    successMessage = 'success',
    errorMessage = 'something went wrong',
    autoHideDuration = 1500
  ) => {
    if (condition) {
      showSnackbar(successMessage, 'success', autoHideDuration);
    } else {
      showSnackbar(errorMessage, 'error', autoHideDuration);
    }
  };
  return {
    showSnackbar,
    showConditionalSackbar,
    closeSnackbar,
  };
};
export default useNotification;
