import { db, generateDocumentId } from 'services/common';
import { categoryDB, menuDB } from 'config/db';
import { Item, Res, Store } from 'interfaces';
import { itemSchemaValidation } from 'validations';
import { trackEvent } from 'services/analytics';
import _ from 'lodash';
import { Name } from 'interfaces/common';
import { sortItems } from 'utils';
type msg = 'success' | 'error' | 'validation error';

export const fetchStoreItmes = async (storeId: string) => {
  const menuItems: Item[] = [];
  try {
    const response = db(menuDB).where('store.storeId', '==', storeId);
    const data = await response.get();
    data.docs.forEach((item: any, index) => {
      const itemToPush = item.data();
      menuItems.push(itemToPush);
    });
  } catch (error) {
    console.log('err while fetching itmes', error);
  }
  const sorted = sortItems(menuItems);
  const test = sorted.map(
    (item) => `${item.categoryIndex}-${item.itemIndex}-${item.name.default}`
  );
  console.log('fetch items', test);
  console.log('full fetch', sorted);
  return sorted;
};
export const addMenuItem = async (menu: Item) => {
  const response: Res<undefined, msg> = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    const validation = itemSchemaValidation(menu);
    console.log('validation', validation);
    if (validation.isValid) {
      await db(menuDB).doc(menu.id).set(validation.data);
      response.success = true;
      response.message = 'success';
      trackEvent('item_add');
    } else {
      response.message = 'validation error';
    }
  } catch (error) {
    console.log('error while adding new item', error);
  }
  return response;
};
export const editMenuItem = async (menu: Item) => {
  const response: Res<undefined, msg> = {
    data: undefined,
    message: 'error',
    success: false,
  };
  try {
    const validation = itemSchemaValidation(menu);
    console.log('validation', validation, menu);
    if (validation.isValid) {
      await db(menuDB).doc(menu.id).update(validation.data);
      response.success = true;
      response.message = 'success';
      trackEvent('item_update');
    } else {
      response.message = 'validation error';
    }
  } catch (error) {
    console.log('error while editing the menu', error);
  }
  return response;
};
export const deleteMenuItem = async (id: string) => {
  try {
    await db(menuDB).doc(id).delete();
    trackEvent('item_delete');
    return { success: true, message: 'Menu Item Deleted' };
  } catch (error) {
    return { success: false, message: 'Menu deletion Failed' };
  }
};
export const fetchStoreItmesByCategory = async (cateogry: {
  name: Name;
  id: string;
}) => {
  const menuItems: Item[] = [];
  try {
    const response = db(menuDB).where('category', 'array-contains', cateogry);
    const data = await response.get();
    data.docs.forEach((item: any, index) => {
      const itemToPush = item.data();
      menuItems.push(itemToPush);
    });
  } catch (error) {
    console.log('err while fetching itmes', error);
  }
  const sorted = sortItems(menuItems);
  return sorted;
};
