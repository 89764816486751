import joi from 'joi';

export const emailSchema = joi
  .string()
  .trim()
  .lowercase()
  .email({ tlds: { allow: false } })
  .default('');

export default emailSchema;
