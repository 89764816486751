export default {
  resources: {
    en: {
      translation: {
        helloWorld: 'Hello World',
        eat: 'eat',
        healthy: 'HEALTHY',
        error: 'error',
        download_QR_code: 'Download QR Code',
        too_many_otp_requests_try_later:
          'You have tried too many times, please try again later.',
        sending_verification_code: 'sending the verification code',
        invalid_verification_code: 'Invalid verification code',
        something_went_wrong_please_try_again_later:
          'Something went wrong please try again later',
        live: 'live',
        long: 'LONG',
        restaurant: 'Restaurant',
        coffee: 'Coffee',
        invalid_email: 'Invalid email',
        strong: 'STRONG',
        menu: 'Menu',
        home: 'Home',
        store: 'Store',
        addMenu: 'Menu',
        copied: 'Copied',
        resetColor: 'reset color',
        addCategory: 'Category',
        visitStore: 'Go to website',
        verifying: 'verifying',
        copy_store_link: 'Copy website link',
        copy: 'Copy',
        settings: 'Settings',
        add: 'add',
        logout: 'logout',
        email: 'Email',
        email_is_already_in_use: 'The email is already in use',
        creating_store_in_progress: 'creating your store, please wait ...',
        sortItems: 'Sort items',
        wrong_information: 'wrong information',
        sortCategories: 'Sort categories',
        password: 'Password',
        the_password_must_be_more_than_6_characters:
          'the password must be more than 6 characters',
        resetPassword: 'Reset Password',
        dontHaveAccountSignup: "Don't have an account? Sign up",
        signIn: 'Sign in',
        welcome: 'Welcome',
        success: 'success',
        something_is_wrong_please_try_again:
          'something is wrong please try again',
        to: 'to',
        invalid_data: 'Invalid data',
        foodlee: 'foodlee',
        yourName: 'Your name',
        storeName: 'Store name',
        storeType: 'Store type',
        chooseYourCountryAndCurrency: 'choose your store currency & country',
        signup: 'Sign up',
        alreadyHaveAccountSignIn: 'Already have an account? Sign in',
        storeNameInArabic: 'Store Name (Arabic)',
        storeNameInEnglish: 'Store Name (English)',
        descriptionInEnglish: 'Description (English)',
        descriptionInArabic: 'Description (Arabic)',
        selectCategory: 'Select Category',
        facebook_profile_link: 'Facebook profile link',
        editMenu: 'Edit menu',
        edit: 'Edit',
        english: 'English',
        arabic: 'Arabic',
        cancel: 'cancel',
        delete: 'delete',
        save: 'save',
        contact: 'contact',
        email_is_sent: 'An email has been sent',
        removeImage: 'Remove Image',
        email_does_not_exist: 'The email does not exist',
        more: 'More',
        noItems: 'No items',
        calories: 'calories',
        export_items: 'Export Items',
        instagram_profile_link: 'Instagram profile link',
        verify_your_phone_number: 'Verify your phone number',
        whatsapp_phone_number: 'WhatsApp phone number',
        phone_is_already_in_use: 'The phone number is already in use',
        verify: 'Verify',
        verified: 'Verified',
        snapchat_profile_link: 'Snapchat profile link',
        twitter_profile_link: 'Twitter profile link',
        phone_number_is_incorrect: 'The phone number is incorrect',
        verification_code_has_been_sent: 'a verification code has been sent',
        add_your_phone_number: 'add your phone number',
        verification_code: ' verification code',
        linkedin_profile_link: 'LinkedIn profile link',
        resend: 'resend',
        whatsapp_number_is_incorrect: 'the WhatsApp phone number is incorrect',
        account_is_created_successfully: 'The account is created successfully',
        link_is_incorrect: 'the link is incorrect',
        check_your_phone_for_the_verification_code:
          'check your phone for the verification code',
        lorem: `If you need help with adding your items or anything, we will be more than happy to help. Please contact us at hi@foodlee.io`,
      },
    },
    ar: {
      translation: {
        welcome: 'هلا بك',
        restaurant: 'مطعم',
        snapchat_profile_link: 'رابط حسابك في سناب شات',
        whatsapp_phone_number: 'رقم جوال الواتس اب',
        twitter_profile_link: 'رابط حسابك في تويتر',
        instagram_profile_link: 'رابط حسابك في انستقرام',
        facebook_profile_link: 'رابط حسابك في فيسبوك',
        linkedin_profile_link: 'رابط حسابك في لينكد ان',
        whatsapp_number_is_incorrect: 'رقم جوال الواتس اب غير صحيح',
        coffee: 'كوفي',
        error: 'خطأ',
        copied: 'تم النسخ',
        email_is_sent: 'تم إرسال الإيميل بنجاح',
        email_does_not_exist: 'الإيميل غير مسجل من قبل',
        too_many_otp_requests_try_later:
          'لقد قمت بالكثير من المحاولات، الرجاء المحاولة في وقت لاحق',
        email_is_already_in_use: 'الايميل مستخدم مسبقا',
        check_your_phone_for_the_verification_code:
          'تم ارسال رمز التحقق. الرجاء الاطلاع على جوالك',
        resend: 'ارسال مرة اخرى',
        verification_code: 'رمز التحقق',
        invalid_verification_code: 'رمز التحقق غير صحيح',
        invalid_email: 'الإيميل غير صحيح',
        account_is_created_successfully: 'تم إنشاء الحساب بنجاح',
        add_your_phone_number: 'ادخل رقم الجوال',
        phone_number_is_incorrect: 'رقم الجوال غير صحيح',
        verify_your_phone_number: 'التحقق من رقم الجوال',
        verify: 'تحقق',
        verifying: 'جاري التحقق',
        link_is_incorrect: 'الرابط غير صحيح',
        the_password_must_be_more_than_6_characters:
          'كلمة السر يجب ان تكون اكثر من 6 احرف او ارقام',
        creating_store_in_progress: '...جاري إنشاء متجرك، الرجاء الانتظار',
        phone_is_already_in_use: 'رقم الجوال مستخدم مسبقا',
        verification_code_has_been_sent: 'تم ارسال رمز التحقق',
        verified: 'تم التحقق بنجاح',
        something_is_wrong_please_try_again:
          'حدث خطأ، الرجاء المحاولة مره اخرى',
        invalid_data: 'المعلومات غير صحيحة',
        removeImage: 'إزالة الصورة',
        more: 'المزيد',
        copy_store_link: 'نسخ رابط الموقع',
        copy: 'نسخ',
        download_QR_code: 'QR Code تحميل رمز الاستجابة السريعة',
        success: 'تمت بنجاح',
        to: 'في',
        sending_verification_code: 'جاري إرسال رمز التحقق',
        export_items: 'تصدير المنتجات',
        foodlee: 'فودلي',
        helloWorld: 'Hallo Welt',
        eat: 'eat (arabic)',
        visitStore: 'زيارة الموقع',
        healthy: '',
        noItems: 'لا توجد منتجات',
        wrong_information: 'المعلومات خاطئة',
        menu: 'المنيو',
        logout: 'تسجيل خروج',
        english: 'انجليزي',
        editMenu: 'تعديل على الطبق',
        selectCategory: 'اختيار صنف',
        edit: 'تعديل',
        live: 'نشر',
        arabic: 'عربي',
        sortItems: 'تريب المنتجات',
        sortCategories: 'تريب الاصناف',
        settings: 'الأعدادات',
        cancel: 'إلغاء',
        delete: 'حف',
        save: 'حفظ',
        email: 'ايميل',
        password: 'كلمة المرور',
        signup: 'إنشاء حساب جديد',
        yourName: 'اسمك الكامل',
        something_went_wrong_please_try_again_later:
          'حدث خطأ، الرجاء المحاولة مرة اخرى',
        storeName: 'اسم المتجر',
        storeType: 'نوع المتجر',
        calories: 'السعرات الحرارية',
        chooseYourCountryAndCurrency: 'اختر الدولة والعملة الخاصة بالمتجر',
        alreadyHaveAccountSignIn: 'عندك حساب؟ سجل الدخول',
        resetPassword: 'استرداد كلمة المرور',
        dontHaveAccountSignup: 'ماعندك حساب؟ انشاء حساب جديد',
        signIn: 'تسجل الدخول',
        storeNameInArabic: 'اسم المتجر (عربي)',
        storeNameInEnglish: 'اسم المتجر (انجليزي)',
        descriptionInEnglish: 'الوصف (انجليزي)',
        descriptionInArabic: 'الوصف (عربي)',
        addMenu: 'طبق جديد',
        resetColor: 'إعادة تعين اللون',
        addCategory: 'صنف جديد',
        home: 'الرئيسية',
        store: 'المتجر',
        add: 'إضافة',
        contact: 'contact Ar',
        lorem:
          'احنا متواجدين على مدار الساعة لخدمتك. اذا واجهتك اي مشاكل او تحتاج مساعدة في اضافة منتجاتك ماعليك الا ترسلنا على هذا الايمل hi@foodlee.io  ومايصير خاطرك الا طيب.',
      },
    },
  },
  lng: localStorage.getItem('lang') || 'en',
  fallbackLng: 'ar',
  interpolation: {
    escapeValue: false,
  },
};
