import { useState } from 'react';
import { User, StoreIntialInformation } from 'interfaces';
import { createUserObject } from 'utils/initial-objects';
import produce from 'immer';
import { useName as nameFunctions, useError, useNotification } from 'hooks';
import _ from 'lodash';
import { userSchemaValidation } from 'validations';

import {
  signUpWithEmailAndPassword,
  signInWithEmailAndPassword,
  logUserOut,
  sendResetPasswordEmail,
  saveUserInformation,
} from 'services/user';
import paths from 'constant/paths';
import { createNewStore } from 'services/store';
import { useTranslation } from 'react-i18next';
import { isEmailAvailable, removeUserPhone } from 'services/user/crud-user';
import { getCurrentUser } from 'services/common';

const fields = {
  phoen: 'phone',
  email: 'email',
  personName: 'name.default',
  password: 'password',
  storeName: 'storesAccess[0].name.default',
};

const useUser = (user?: User) => {
  console.log('user', user);
  const userToEdit = user ? user : createUserObject();
  const [values, setValues] = useState<User>(userToEdit);
  console.log('user values', values);
  const [isFetching, setIsFetching] = useState(false);
  const [creatingStoreProgress, setCreatingStoreProgress] = useState(0);
  const [password, setPassword] = useState('');

  const [store, setStore] = useState<StoreIntialInformation>({
    currency: 'SAR',
    type: ['restaurant'],
  });
  const [t] = useTranslation();
  const { showSnackbar, showConditionalSackbar } = useNotification();
  const { updateError, hasError, getErrorMessage, bulkErrorUpdate } =
    useError();

  const updateState = (callback: (draft: any) => void, field?: string) => {
    const copy = produce(values, (draft) => callback(draft));
    setValues(copy);

    if (field) {
      updateError(field, false, '');
    }
    return copy;
  };
  const resetNewUserData = () => {
    setValues(createUserObject());
    setPassword('');
    setStore({
      currency: 'SAR',
      type: ['restaurant'],
    });
  };
  const onNewUserPhoneVerfied = async (phone: string) => {
    setCreatingStoreProgress(20);
    const updateProgress = () => {
      setCreatingStoreProgress((prev) => prev + 20);
    };
    const updatedValues: User = {
      ...values,
      phone,
      isVerified: true,
    };
    let success = false;
    let successMsg = t('account_is_created_successfully');
    let errMsg = t('invalid_data');
    const validation = userSchemaValidation(updatedValues);
    const isValid = validation.isValid;
    const validatedData: User | undefined = validation.data;
    if (isValid && validatedData) {
      const newUserAccount = await signUpWithEmailAndPassword(
        validatedData.email,
        password,
        validatedData
      );
      updateProgress();
      console.log('newUserAccount', newUserAccount);
      if (newUserAccount.success) {
        const saveNewUser = await saveUserInformation(validatedData);
        updateProgress();
        console.log('saveNewUser', saveNewUser);
        if (saveNewUser.success) {
          const newStoreResult = await createNewStore(validatedData, store);
          updateProgress();
          console.log('newStoreResult', newStoreResult);
          if (newStoreResult.success) {
            bulkErrorUpdate([]);
            const phoneRemoved = await removeUserPhone(validatedData.email);
            updateProgress();
            console.log('phone removed', phoneRemoved);
            success = phoneRemoved;
          }
        }
      } else {
        if (newUserAccount.message === 'email is already used') {
          errMsg = t('email_is_already_in_use');
        }
        if (
          newUserAccount.message === 'password must be more than 6 characters'
        ) {
          errMsg = t('the_password_must_be_more_than_6_characters');
        }
      }
    }
    if (success === false) {
      resetNewUserData();
      getCurrentUser()?.delete();
      setCreatingStoreProgress(0);
    }
    showConditionalSackbar(success, successMsg, errMsg);
    return success;
  };
  const validateUserData = async (redirectToHomeIfSuccess = true) => {
    const validation = userSchemaValidation(values);
    const isPasswordValid = password.length > 5;
    const isValidInfo = validation.isValid;
    const validatedData = validation.data;
    let success = false;
    setIsFetching(true);
    if (isValidInfo && isPasswordValid) {
      setValues(validatedData);
      const email = values.email;
      const emailIsAvalible = await isEmailAvailable(email);
      console.log('emailIsAvalible', emailIsAvalible);
      if (emailIsAvalible) {
        bulkErrorUpdate([]);
        success = true;
      } else {
        let errorMsg = t('email_is_already_in_use');
        showSnackbar(errorMsg, 'error');
      }
    } else {
      let errMsg = t('wrong_information');
      if (isPasswordValid === false && isValidInfo === true) {
        errMsg = t('the_password_must_be_more_than_6_characters');
      } else {
        bulkErrorUpdate(validation.keys, false);
      }
      showSnackbar(errMsg, 'error');
    }
    setIsFetching(false);
    return success;
  };
  const findStoreById = (storeId: string) => {
    const stores = values.storesAccess;
    let index = -1;
    const storeInfo = stores.find((store, i) => {
      if (store.storeId === storeId) {
        index = i;
        return store;
      }
    });
    return { storeInfo, index };
  };

  //auth
  const userSignIn = async () => {
    setIsFetching(true);
    const successMsg = t('success');
    let errorMsg = t('something_is_wrong_please_try_again');
    const email = values.email;
    const signIn = await signInWithEmailAndPassword(email, password);
    errorMsg = signIn.message;

    const duration = 5000;
    setIsFetching(false);

    showConditionalSackbar(signIn.success, successMsg, errorMsg, duration);
    if (signIn.success) {
      bulkErrorUpdate([]);
      window.location.replace(paths.home);
    }
  };
  const resetPassword = async (email: string) => {
    setIsFetching(true);
    const result = await sendResetPasswordEmail(email);
    setIsFetching(false);
    showSnackbar(t(result.message), result.success ? 'success' : 'error');
    return result;
  };

  // getters
  const getUserStoreAccess = (storeId: string = '') => {
    if (storeId === '') return values.storesAccess[0];
    return findStoreById(storeId).storeInfo;
  };
  const getStoreName = (storeId: string = '', lang?: any) => {
    const storeInfo = getUserStoreAccess(storeId);
    const name = storeInfo?.name;
    const displayName = nameFunctions(name).getAnyTranslationValue(lang);
    return displayName;
  };
  const getUserPhone = () => {
    return values.phone;
  };
  const getUserEmail = () => {
    return values.email;
  };
  const getUserName = (lang?: any) => {
    const nameObj = values.name;
    const name = nameFunctions(nameObj).getAnyTranslationValue(lang);

    return name;
  };

  //changes
  const onUserNameChange = (value: any) => {
    const update = (draft: User) => {
      const name = draft.name;
      const updatedName = nameFunctions(name).onDefaultLangugaeChange(value);
      draft.name = updatedName;
    };
    return updateState(update, fields.personName);
  };

  const onStoreNameChange = (value: any, storeId?: string) => {
    const update = (draft: User) => {
      const store = draft.storesAccess[0];
      const name = store.name;
      const storeId = store.storeId;
      const updatedName = nameFunctions(name).onDefaultLangugaeChange(value);

      draft.storesAccess = [{ name: updatedName, storeId: storeId }];
    };
    return updateState(update, fields.storeName);
  };

  const onEmailChange = (value: any) => {
    const update = (draft: User) => {
      draft.email = value;
    };
    return updateState(update, fields.email);
  };

  const onPhoneChange = (value: any) => {
    const update = (draft: User) => {
      draft.phone = value;
    };
    return updateState(update, fields.phoen);
  };
  // initialStoreInfo

  const updateStore = (callback: (draft: StoreIntialInformation) => void) => {
    const copy = produce(store, (draft: StoreIntialInformation) =>
      callback(draft)
    );
    setStore(copy);
    return copy;
  };
  const onCurrencyChange = (currency: string) => {
    const update = (draft: StoreIntialInformation) => {
      draft.currency = currency;
    };
    return updateStore(update);
  };
  const onStoreTypeChange = (type: 'coffee' | 'restaurant') => {
    const update = (draft: StoreIntialInformation) => {
      draft.type = [type];
    };
    return updateStore(update);
  };
  return {
    hasError,
    getErrorMessage,
    onNewUserPhoneVerfied,
    // common
    validateUserData,
    // changes
    isFetching,
    creatingStoreProgress,
    onPhoneChange,
    onEmailChange,
    onStoreNameChange,
    onUserNameChange,
    setPassword,
    password,
    // getters
    userSignIn,
    getStoreName,
    getUserPhone,
    getUserEmail,
    getUserName,
    resetNewUserData,
    fields,
    logUserOut,
    resetPassword,
    initialStoreInfo: store,
    onStoreTypeChange,
    onCurrencyChange,
  };
};

export default useUser;
