import { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
}));
type OnError = (error: Error, info: { componentStack: string }) => any;
export interface Props {
  children?: any;
  fallback?: ReactElement;
  onError: OnError;
  errorMsg?: string;
}

const CustomErrorBoundary = (props: Props): ReactElement => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { fallback, onError, children, errorMsg } = props;
  const handleError: OnError = (error, info) => {
    console.log('error', error);
    console.log('info', info);
    onError(error, info);
  };
  const Fallback = () => {
    return (
      <Grid
        container
        style={{ minHeight: '100vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography color="error">
            {errorMsg || t('something_went_wrong_please_try_again_later')}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <ErrorBoundary fallback={fallback || <Fallback />} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};

const defaultProps: Props = {
  onError: () => undefined,
};

CustomErrorBoundary.defaultProps = defaultProps;

export default CustomErrorBoundary;
