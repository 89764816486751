import objectIdString from 'utils/unique';
import { generateDocumentId } from 'services/common';
import { userDB, storeDB } from 'config/db';
import { NameDB, User } from 'interfaces';
import { name } from 'validations/default-values';
import { createTimestamp } from 'utils/dates';
export const createUserObject = (dbName: NameDB = userDB) => {
  const now = createTimestamp();
  const user: User = {
    accountType: ['business'],
    createdAt: now,
    userObjectId: objectIdString(),
    docId: generateDocumentId(dbName),
    storesAccess: [{ storeId: generateDocumentId(storeDB), name }],
    email: '',
    isVerified: false,
    lastSignedIn: now,
    name: name,
    phone: '',
  };
  return user;
};
