// import React, { useState } from 'react';
// import 'react-image-crop/dist/ReactCrop.css';
// import ReactCrop, { ReactCropProps } from 'react-image-crop';

// interface Props {
//   src: string;
//   className?: string;
// }

// const ImageCrop = (props: Props) => {
//   const { src, className } = props;
//   const [crop, setCrop] = useState({ aspect: 16 / 9 });
//   console.log('crop', crop);
//   return (
//     <ReactCrop
//       src={src}
//       className={className}
//       crop={crop}
//       onChange={(newCrop: any) => setCrop(newCrop)}
//       onComplete={(x) => console.log('x', x)}
//     />
//   );
// };

// export default ImageCrop;
import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class CropImage extends PureComponent {
  state = {
    src: null,
    crop: {
      unit: 'px',
      width: 343,
      height: 310,
    },
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        'image/jpeg',
        1
      );
    });
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div style={{ width: '100%', height: '100%' }}>
        {/* <div>
          <input type="file" accept="image/*" onChange={this.onSelectFile} />
        </div> */}
        {this.props.src && (
          <ReactCrop
            src={this.props.src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}
        {croppedImageUrl && (
          <img
            alt="Crop"
            style={{ width: 343, height: 310 }}
            src={croppedImageUrl}
          />
        )}
      </div>
    );
  }
}
export default CropImage;
